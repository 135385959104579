<template>
  <UiListItem
    :value="item.id"
    :min-height="38"
    :base-color="item.color ?? 'primary'"
    v-bind="item.link && { to: item.link }"
    :disabled="item.disabled"
    :active="activeLinkComparison(item.link)"
    class="ui-list-item"
    active-class="ui-list-item-active"
  >
    <template v-if="item.icon" #prepend>
      <UiBadge
        v-if="item.badge && item.badge !== '0' && !isSidebarOpen"
        :content="getShortBadge(item.badge)"
        color="primary-200"
        text-color="primary"
      >
        <UiIcon :icon="item.icon" />
      </UiBadge>
      <UiIcon v-else :icon="item.icon" />
    </template>
    <UiListItemTitle>
      <AppTranslate :identifier="item.label" />
    </UiListItemTitle>
    <template v-if="item.badge && isSidebarOpen" #append>
      <AppSidebarBadge :count="item.badge" />
    </template>
  </UiListItem>
</template>

<script setup lang="ts">
import { getShortBadge } from '~/utils/badge/index.js';
import { UiBadge, UiIcon, UiListItem, UiListItemTitle } from '~/ui/index.js';
import AppSidebarBadge from '~/app/layouts/sidebar/components/AppSidebarBadge.vue';
import { AppTranslate, useRoute } from '~/infrastructure/index.js';
import { type SidebarItem } from '~/app/layouts/sidebar';

export interface Props {
  item: SidebarItem;
  isSidebarOpen: boolean;
}

defineProps<Props>();

const route = useRoute();

const activeLinkComparison = (link: string | undefined): boolean => {
  if (link === undefined) return false;

  if (link.includes('warranty')) {
    return link.includes('warranty') && route.path.includes('warranty');
  }

  if (link.includes('orders')) {
    return route.path.includes(link.split('?')[0]);
  }

  return link === route.path;
};
</script>
