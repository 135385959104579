<template>
  <template v-if="item.isVisible">
    <template v-if="!item.children">
      <UiTooltip v-if="!isSidebarOpen">
        <template #activator="data">
          <UiSidebarListItemView
            v-bind="data.props"
            :item="item"
            :is-sidebar-open="isSidebarOpen"
          />
        </template>
        {{ t(item.label) }}
      </UiTooltip>

      <UiSidebarListItemView
        v-else
        v-bind="props"
        :item="item"
        :is-sidebar-open="isSidebarOpen"
      />
    </template>

    <UiMenu
      v-else
      location="end"
      :open-delay="100"
      :close-delay="100"
      transition="slide-x-transition"
      class="sidebar-menu"
      :content-class="isScrollingBottom ? '' : 'offset-top'"
      open-on-hover
      :max-width="500"
      @update:model-value="handleUpdate"
    >
      <template #activator="{ props: activatorProps, isActive }">
        <UiListItem
          :value="item.id"
          v-bind="activatorProps"
          :base-color="item.color ?? 'primary'"
          class="ui-list-item"
          :class="{ 'ui-list-item-active': isActive }"
          :to="item.link"
          :min-height="38"
        >
          <template v-if="item.icon" #prepend>
            <UiIcon :icon="item.icon" />
          </template>
          <UiListItemTitle>
            <AppTranslate :identifier="item.label" />
          </UiListItemTitle>
          <template v-if="item.children" #append>
            <UiIcon icon="$chevronRight" />
          </template>
        </UiListItem>
      </template>
      <UiList
        v-if="item.children"
        ref="uiListRef"
        class="ui-list"
        elevation="0"
        :rounded="0"
        style="width: auto"
      >
        <UiInput
          v-if="item.searchInput"
          :model-value="searchValue"
          :single-line="true"
          :hide-details="true"
          :label="t('common.buttons.Search.text')"
          density="comfortable"
          class="pt-5 pb-3 px-4 search-input"
          @click.stop
          @update:model-value="handleSearchUpdate"
        />
        <template v-if="item.sortByDesc">
          <template
            v-for="(sortItem, index) in searchBySortedItem"
            :key="index"
          >
            <UiListItem v-if="sortItem.item?.length" :min-height="32">
              <UiText color="grey-400" variant="subtitle-4">
                {{ sortItem.capitalLetter }}
              </UiText>
            </UiListItem>
            <UiSidebarMenuItemInner
              v-for="(child, i) in sortItem.item"
              :key="i"
              :menu-offset="isScrollVisible ? '6px' : '0'"
              :item="child"
              :is-scrolling="isScrollingBottom"
            />
          </template>
        </template>

        <UiSidebarMenuItemInner
          v-for="(child, i) in searchByDefaultItem"
          v-else
          :key="i"
          :menu-offset="isScrollVisible ? '6px' : '0'"
          :item="child"
          :is-scrolling="isScrollingBottom"
        />

        <UiText
          v-if="!searchByDefaultItem.length"
          variant="body-3"
          class="text-center pt-4"
          color="grey-800"
        >
          <AppTranslate identifier="common.nothingFound.text" />
        </UiText>
      </UiList>

      <Transition name="hint-fade">
        <UiSidebarScrollHint v-if="!arrivedState.bottom && isScrollVisible" />
      </Transition>
    </UiMenu>
  </template>
</template>

<script setup lang="ts">
import { ref, type ComponentPublicInstance, type Ref, computed } from 'vue';
import { useI18n, useScroll, AppTranslate } from '~/infrastructure';
import {
  UiMenu,
  UiListItem,
  UiIcon,
  UiList,
  UiListItemTitle,
  UiInput,
  UiText,
  UiTooltip,
} from '~/ui';
import UiSidebarMenuItemInner from './UiSidebarMenuItemInner.vue';
import useSidebarSearch from './useSidebarSearch';
import UiSidebarScrollHint from './UiSidebarScrollHint.vue';
import { type SidebarItem } from '~/app/layouts/sidebar';
import UiSidebarListItemView from '~/ui/UiSidebar/UiSidebarListItemView.vue';

export interface Props {
  item: SidebarItem;
  isSidebarOpen: boolean;
}

const props = defineProps<Props>();

const emit =
  defineEmits<(e: 'update', value: boolean, id: string | number) => void>();

const { t } = useI18n();

const uiListRef = ref<ComponentPublicInstance | null>(null);
const isScrollVisible = ref(false);

const { isScrollingBottom } = useScroll();
const { arrivedState } = useScroll(uiListRef as Ref<HTMLElement | null>, {
  offset: {
    bottom: 10,
  },
});

const { searchValue, searchByDefaultItem, searchBySortedItem } =
  useSidebarSearch(props);

const handleSearchUpdate = (value: string): void => {
  searchValue.value = value;
  checkScrollExist();
};

const handleUpdate = (value: boolean): void => {
  emit('update', value, props.item.id);
  checkScrollExist();
};

const checkScrollExist = (): void => {
  setTimeout(() => {
    if (uiListRef.value === null) return;

    isScrollVisible.value =
      uiListRef.value.$el.scrollHeight > uiListRef.value.$el.clientHeight;
  }, 400);
};

const colorOnHover = computed(() => {
  return props.item.color !== undefined
    ? `--v-theme-${props.item.color}`
    : '--v-theme-primary';
});
</script>

<style scoped lang="scss">
.ui-list {
  padding: 0;
  border-bottom: 0;
  border-top: 0;
  border-left: 1px solid rgb(var(--v-theme-grey-100));
  border-right: 1px solid rgb(var(--v-theme-grey-100));
  background-color: rgb(var(--v-theme-grey-50)) !important;
  transition: top var(--layout-transition-duration)
    var(--layout-transition-timing);
}

.hint-fade-enter-active,
.hint-fade-leave-active {
  transition: opacity 0.2s ease;
}

.hint-fade-enter-from,
.hint-fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.sidebar-menu .v-overlay__content {
  transition: top 200ms;
  max-height: 100vh !important;

  &.offset-top {
    top: var(--header-height) !important;
    max-height: calc(100vh - var(--header-height)) !important;
  }

  .ui-list {
    @include scrollbar;

    .search-input {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: inherit;
      min-width: 248px;
    }
  }
}

.ui-list-item {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  position: relative;
}

.ui-list-item:hover {
  background: rgb(var(--v-theme-white)) !important;
  color: rgb(var(v-bind(colorOnHover))) !important;
}

.ui-list-item-active {
  background-color: rgb(var(--v-theme-white)) !important;
}

.ui-list-item .v-list-item__overlay {
  background: rgb(var(--v-theme-white)) !important;
}

.ui-list-item .v-list-item__overlay {
  background: rgb(var(--v-theme-white)) !important;
}

.ui-list-item.v-list-item::after {
  transition: none;
}
</style>
