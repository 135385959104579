<template>
  <VBadge
    ref="uiBadge"
    :content="content"
    :color="color"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :inline="inline"
    :text-color="textColor"
  >
    <slot />
  </VBadge>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { VBadge } from 'vuetify/components/VBadge';

export interface Props {
  content: string | number;
  color?: string;
  textColor?: string;
  offsetX?: string | number;
  offsetY?: string | number;
  animate?: boolean;
  inline?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  color: 'primary',
  offsetX: 0,
  offsetY: 0,
  inline: false,
  textColor: undefined,
});

const uiBadge = ref();
defineExpose({ uiBadge });

function animateBadge(): void {
  uiBadge.value?.$el
    .querySelector('.v-badge__badge')
    ?.animate(
      [
        { transform: 'scale(1)' },
        { transform: 'scale(1.8)' },
        { transform: 'scale(1)' },
      ],
      {
        duration: 300,
        easing: 'ease',
      },
    );
}

watch(
  () => props.content,
  () => {
    if (!props.animate) return;
    animateBadge();
  },
);
</script>
