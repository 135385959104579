<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.2174 5.181L4.50634 3.373C4.46737 3.34693 4.42152 3.33301 4.37463 3.33301C4.32773 3.33301 4.28189 3.34693 4.24291 3.373L1.77254 5.02086C1.74335 5.04031 1.71885 5.06599 1.70079 5.09606C1.68274 5.12613 1.67158 5.15984 1.66814 5.19474C1.66469 5.22965 1.66904 5.26488 1.68087 5.2979C1.6927 5.33092 1.71171 5.3609 1.73654 5.38568L4.17848 7.82762C4.24068 7.88985 4.29002 7.96372 4.32368 8.04501C4.35734 8.12631 4.37465 8.21343 4.37463 8.30142C4.37463 8.67098 4.67502 8.97137 5.04458 8.97137H15.0758C15.4453 8.97137 15.7457 8.67098 15.7457 8.30142C15.746 8.12379 15.8165 7.95347 15.9419 7.82762L18.3838 5.38568C18.4086 5.3609 18.4276 5.33092 18.4395 5.2979C18.4513 5.26488 18.4557 5.22965 18.4522 5.19474C18.4488 5.15984 18.4376 5.12613 18.4196 5.09606C18.4015 5.06599 18.377 5.04031 18.3478 5.02086L15.8784 3.373C15.8394 3.34693 15.7936 3.33301 15.7467 3.33301C15.6998 3.33301 15.6539 3.34693 15.6149 3.373L12.9029 5.181M4.37463 9.59393C4.37463 8.8188 4.37463 8.43219 4.51866 8.08347C4.6627 7.73571 4.9375 7.46091 5.48521 6.9132L6.10682 6.29158C6.65453 5.74387 6.92933 5.46907 7.2771 5.32504C7.62581 5.181 8.01338 5.181 8.78756 5.181H11.3328C12.1079 5.181 12.4945 5.181 12.8432 5.32504C13.192 5.46907 13.4658 5.74387 14.0135 6.29158L14.6351 6.9132C15.1838 7.46091 15.4567 7.73571 15.6017 8.08347C15.7457 8.43219 15.7457 8.8188 15.7457 9.59393V13.7093C15.7457 15.4965 15.7457 16.3891 15.1904 16.9444C14.6351 17.4997 13.7425 17.4997 11.9554 17.4997H8.16499C6.37784 17.4997 5.48521 17.4997 4.92992 16.9444C4.37463 16.3891 4.37463 15.4965 4.37463 13.7093V9.59393Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
