<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.99935 1.66663C7.78971 1.66663 5.66862 2.54514 4.10742 4.10738C2.54461 5.67024 1.66645 7.78978 1.66602 9.99996C1.66602 12.2092 2.54492 14.3303 4.10742 15.8925C5.66862 17.4548 7.78971 18.3333 9.99935 18.3333C12.209 18.3333 14.3301 17.4548 15.8913 15.8925C17.4538 14.3303 18.3327 12.2092 18.3327 9.99996C18.3327 7.79071 17.4538 5.66962 15.8913 4.10738C14.3301 2.54514 12.209 1.66663 9.99935 1.66663Z"
      fill="url(#paint0_linear_18696_59612)"
    />
    <path
      d="M5.43843 9.91187C7.86811 8.85353 9.48791 8.15575 10.2978 7.81864C12.6129 6.85601 13.0934 6.68882 13.4072 6.68315C13.4762 6.68205 13.6298 6.69911 13.7301 6.78016C13.8134 6.84852 13.8369 6.94097 13.8486 7.00588C13.859 7.07072 13.8733 7.21851 13.8616 7.33387C13.7366 8.65158 13.1936 11.8492 12.9176 13.3251C12.8017 13.9496 12.5712 14.159 12.3486 14.1794C11.8642 14.224 11.497 13.8597 11.0283 13.5525C10.2952 13.0716 9.88114 12.7724 9.1689 12.3033C8.34598 11.7611 8.87983 11.463 9.34858 10.9761C9.47098 10.8486 11.6038 8.90913 11.6442 8.73322C11.6494 8.71121 11.6546 8.62918 11.6051 8.58595C11.5569 8.54259 11.4853 8.55744 11.4332 8.56916C11.359 8.58582 10.1884 9.3603 7.91759 10.8925C7.58556 11.1209 7.28478 11.2322 7.01395 11.2263C6.71707 11.2199 6.14416 11.0581 5.71838 10.9198C5.19754 10.7501 4.78218 10.6604 4.81864 10.3723C4.83687 10.2223 5.0439 10.0688 5.43843 9.91187Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_18696_59612"
        x1="834.999"
        y1="1.66663"
        x2="834.999"
        y2="1668.33"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2AABEE" />
        <stop offset="1" stop-color="#229ED9" />
      </linearGradient>
    </defs>
  </svg>
</template>
