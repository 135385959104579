import { type UserInfoResponse } from './types.d';
import userSelectedSerializer from './serializers/user-selected.serializer';
import userCreditSerializer from './serializers/user-credit.serializer';
import { type UserInfo, userOrderOverviewSerializer } from '~/domains/user';
import userBillToAddressSerializer from '~/domains/user/serializers/user-bill-to-address.serializer.ts';

export default function userInfoSerialiser(data: UserInfoResponse): UserInfo {
  return {
    userId: data.user_id ?? '',
    username: data.username ?? '',
    title: data.title ?? '',
    firstName: data.first_name ?? '',
    secondName: data.middle_name ?? '',
    lastName: data.last_name ?? '',
    fullName: `${data.first_name ?? ''} ${data.last_name ?? ''}`,
    gender: data.sex === 'M' ? 'Male' : 'Female',
    language: data?.languages?.find(
      (lang) => lang.language === data.language,
    ) ?? { name: 'English', language: 'en' },
    languages: data.languages ?? [],
    defaultCompany:
      data.selected?.cid !== ''
        ? data.companies?.find((company) => company.id === data.selected?.cid)
        : null,
    selectedSupplier: {
      id: data.selected?.supplier?.id ?? '',
      name: data.selected?.supplier?.name ?? '',
    },
    companies: data.companies ?? [],
    suppliers: data.suppliers ?? [],
    email: data.email ?? '',
    allowChangeEmail: data.allow_change_email ?? false,
    phone: data.phone ?? '',
    phone2: data.phone2 ?? '',
    mobile: data.mobile ?? '',
    fax: data.fax ?? '',
    manager: {
      fullName:
        data.account_manager?.first_name !== undefined ||
        data.account_manager?.last_name !== undefined
          ? `${data.account_manager?.first_name ?? ''} ${
              data.account_manager?.last_name ?? ''
            }`
          : null,
      mobile: data.account_manager?.mobile ?? null,
      email: data.account_manager?.email ?? null,
      avatarUrl: data.account_manager?.picture_url,
      messengers: data.account_manager?.messengers ?? [],
    },
    currencies: data.currencies ?? [],
    credit: userCreditSerializer(data.credit),
    selected: userSelectedSerializer(data.selected),
    compareOverview: {
      productsCount: data.compare_overview?.products_count,
    },
    esd: data.esd,
    esdOrderConfirmMethod: data.esd_order_confirm_method,
    customerBillToAddress: userBillToAddressSerializer(
      data.customer_bill_to_address,
    ),
    supplierBillToAddress: userBillToAddressSerializer(
      data.supplier_bill_to_address,
    ),
    ordersOverview: userOrderOverviewSerializer(data.orders_overview),
    functions: data.functions,
    canUseDropship: data.can_use_dropship ?? false,
  };
}
