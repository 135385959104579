<template>
  <UiRow no-gutters class="px-3 mt-0" align="center">
    <UiCol class="px-1" cols="auto">
      <UiText variant="body-4" color="grey-400">
        <AppTranslate identifier="header.info.manager.label" />:
      </UiText>
    </UiCol>
    <UiCol cols="auto" class="px-lg-1 px-sm-0">
      <UiDropdown
        v-if="isShowManagerDropdown"
        :close-on-content-click="false"
        class="header-manager-dropdown"
      >
        <template #activator="{ props: activatorProps }">
          <UiButton
            v-bind="activatorProps"
            variant="text"
            size="small"
            color="white"
            caret
            class="header-manager-button"
            style="width: fit-content"
          >
            <UiText variant="body-4" color="white">
              {{ manager?.fullName }}
            </UiText>
            <template #append>
              <UiIcon icon="$mdi-chevron-down" />
            </template>
          </UiButton>
        </template>

        <AppHeaderManagerInfo
          :email="managerEmail"
          :phone="managerPhone"
          :messengers="managerMessengers"
          :avatar="manager?.avatarUrl"
          :manager-name="manager?.fullName"
        />
      </UiDropdown>

      <UiText v-else variant="body-4" color="white" class="px-1">
        {{ manager?.fullName }}
      </UiText>
    </UiCol>
  </UiRow>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { UiButton, UiCol, UiDropdown, UiIcon, UiRow, UiText } from '~/ui';
import { AppTranslate } from '~/infrastructure';
import { type UserInfoManager } from '~/domains/user';
import AppHeaderManagerInfo from './AppHeaderManagerInfo.vue';
import { type ManagerInfo } from '~/app/types.d';

export interface Props {
  manager: UserInfoManager;
}

const props = defineProps<Props>();

const managerMessengers = computed<ManagerInfo[]>(() => {
  const result = [];

  if (
    props.manager?.messengers !== undefined &&
    props.manager?.messengers.length > 0
  ) {
    props.manager?.messengers.forEach((messenger) => {
      result.push({
        icon: `$${messenger.kind === 'facebook' ? 'facebookCircle' : messenger.kind}`,
        label: messenger.name,
        href: messenger.link,
      });
    });
  }

  if (props.manager?.email != null)
    result.push({
      icon: '$teams',
      label: props.manager?.email,
      href: `https://teams.microsoft.com/l/chat/0/0?users=${props.manager?.email}`,
    });

  return result;
});

const managerEmail = computed(() => {
  if (props.manager?.email != null) {
    return {
      icon: '$mail',
      label: props.manager?.email,
      href: `mailto:${props.manager?.email}`,
    };
  }

  return undefined;
});

const managerPhone = computed(() => {
  if (props.manager?.mobile != null)
    return {
      icon: '$phone',
      label: props.manager?.mobile,
      href: `tel:${props.manager?.mobile}`,
    };

  return undefined;
});

const isShowManagerDropdown = computed(() => {
  return (
    managerMessengers.value.length > 0 ||
    managerPhone.value !== undefined ||
    managerEmail.value !== undefined
  );
});
</script>

<style>
.header-manager-dropdown .ui-dropdown__list {
  margin-top: 5px;
}

.header-manager-button {
  padding: 0 4px;

  @media (max-width: 1024px) {
    height: auto !important;
  }
}
</style>
