<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.553 3.50189L17.5263 3.48517L14.1438 1.81873L10.4929 6.68658L12.1725 8.9263C12.1223 9.77096 11.7641 10.5679 11.1658 11.1662C10.5674 11.7645 9.77051 12.1227 8.92586 12.1729L6.68617 10.4933L1.81836 14.1441L3.47094 17.4983L3.48484 17.5267L3.5016 17.5534C3.58979 17.6955 3.71288 17.8126 3.85916 17.8937C4.00543 17.9748 4.17003 18.0171 4.33727 18.0165H5.21008C6.8918 18.0165 8.55706 17.6853 10.1108 17.0417C11.6645 16.3982 13.0762 15.4549 14.2654 14.2657C15.4545 13.0765 16.3978 11.6648 17.0414 10.1111C17.6849 8.55739 18.0162 6.89213 18.0162 5.21041V4.33755C18.0167 4.17032 17.9744 4.00573 17.8934 3.85945C17.8123 3.71317 17.6951 3.59008 17.553 3.50189ZM16.7662 5.21041C16.7662 11.5825 11.5821 16.7665 5.21008 16.7665H4.50383L3.39813 14.5219L6.68641 12.0557L8.51715 13.4287H8.72547C9.97239 13.4273 11.1678 12.9313 12.0495 12.0496C12.9312 11.1679 13.4272 9.97248 13.4286 8.72556V8.51724L12.0556 6.68654L14.5215 3.39845L16.7662 4.50435V5.21041Z"
      fill="currentColor"
    />
  </svg>
</template>
