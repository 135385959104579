export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/[...path].vue'),
    /* no children */
  },
  {
    path: '/account',
    name: '/account',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/account.vue'),
    /* no children */
  },
  {
    path: '/account-settings',
    name: '/account-settings',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/account-settings.vue'),
    /* no children */
  },
  {
    path: '/actions',
    /* internal name: '/actions' */
    /* no component */
    children: [
      {
        path: ':actionType',
        /* internal name: '/actions/[actionType]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/actions/[actionType]/',
            component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/actions/[actionType]/index.vue'),
            /* no children */
          },
          {
            path: 'categories',
            /* internal name: '/actions/[actionType]/categories' */
            /* no component */
            children: [
              {
                path: ':categorySlug',
                name: '/actions/[actionType]/categories/[categorySlug]',
                component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/actions/[actionType]/categories/[categorySlug].vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/basket',
    name: '/basket',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/basket.vue'),
    /* no children */
  },
  {
    path: '/brands',
    /* internal name: '/brands' */
    /* no component */
    children: [
      {
        path: ':brandId',
        /* internal name: '/brands/[brandId]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/brands/[brandId]/',
            component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/brands/[brandId]/index.vue'),
            /* no children */
          },
          {
            path: 'categories',
            /* internal name: '/brands/[brandId]/categories' */
            /* no component */
            children: [
              {
                path: ':categorySlug',
                name: '/brands/[brandId]/categories/[categorySlug]',
                component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/brands/[brandId]/categories/[categorySlug].vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/catalog',
    /* internal name: '/catalog' */
    /* no component */
    children: [
      {
        path: '',
        name: '/catalog/',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/catalog/index.vue'),
        /* no children */
      },
      {
        path: ':slug',
        name: '/catalog/[slug]',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/catalog/[slug].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/change-password',
    name: '/change-password',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/change-password.vue'),
    /* no children */
    meta: {
      "layout": "auth"
    }
  },
  {
    path: '/compare',
    name: '/compare',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/compare.vue'),
    /* no children */
  },
  {
    path: '/confirm-order',
    /* internal name: '/confirm-order' */
    /* no component */
    children: [
      {
        path: ':id',
        name: '/confirm-order/[id]',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/confirm-order/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/create-offer',
    name: '/create-offer',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/create-offer.vue'),
    /* no children */
  },
  {
    path: '/create-password',
    name: '/create-password',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/create-password.vue'),
    /* no children */
    meta: {
      "layout": "auth"
    }
  },
  {
    path: '/cto-apple',
    /* internal name: '/cto-apple' */
    /* no component */
    children: [
      {
        path: ':slug',
        name: '/cto-apple/[slug]',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/cto-apple/[slug].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/data-connectivity',
    name: '/data-connectivity',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/data-connectivity.vue'),
    /* no children */
  },
  {
    path: '/dep',
    /* internal name: '/dep' */
    /* no component */
    children: [
      {
        path: 'enrollments',
        /* internal name: '/dep/enrollments' */
        /* no component */
        children: [
          {
            path: '',
            name: '/dep/enrollments/',
            component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/dep/enrollments/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: '/dep/enrollments/[id]',
            component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/dep/enrollments/[id].vue'),
            /* no children */
          },
          {
            path: 'devices',
            /* internal name: '/dep/enrollments/devices' */
            /* no component */
            children: [
              {
                path: '',
                name: '/dep/enrollments/devices/',
                component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/dep/enrollments/devices/index.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/edifact',
    name: '/edifact',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/edifact.vue'),
    /* no children */
  },
  {
    path: '/forgot-password',
    name: '/forgot-password',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/forgot-password.vue'),
    /* no children */
    meta: {
      "layout": "auth"
    }
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "auth"
    }
  },
  {
    path: '/orders',
    /* internal name: '/orders' */
    /* no component */
    children: [
      {
        path: '',
        name: '/orders/',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/orders/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/orders/[id]',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/orders/[id].vue'),
        /* no children */
      },
      {
        path: 'history',
        /* internal name: '/orders/history' */
        /* no component */
        children: [
          {
            path: '',
            name: '/orders/history/',
            component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/orders/history/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'open',
        /* internal name: '/orders/open' */
        /* no component */
        children: [
          {
            path: '',
            name: '/orders/open/',
            component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/orders/open/index.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/product',
    /* internal name: '/product' */
    /* no component */
    children: [
      {
        path: ':id',
        name: '/product/[id]',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/product/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/promotion',
    /* internal name: '/promotion' */
    /* no component */
    children: [
      {
        path: ':id',
        name: '/promotion/[id]',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/promotion/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/reset-password',
    name: '/reset-password',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/reset-password.vue'),
    /* no children */
    meta: {
      "layout": "auth"
    }
  },
  {
    path: '/service-centers',
    name: '/service-centers',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/service-centers.vue'),
    /* no children */
  },
  {
    path: '/warranty',
    name: '/warranty',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/warranty.vue'),
    children: [
      {
        path: 'check-status',
        name: '/warranty/check-status',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/warranty/check-status.vue'),
        /* no children */
      },
      {
        path: 'create-rma',
        name: '/warranty/create-rma',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/warranty/create-rma.vue'),
        /* no children */
      },
      {
        path: 'history',
        name: '/warranty/history',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/warranty/history.vue'),
        /* no children */
      },
      {
        path: 'processing',
        name: '/warranty/processing',
        component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/warranty/processing.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/xml-exchange',
    name: '/xml-exchange',
    component: () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/pages/xml-exchange.vue'),
    /* no children */
  }
]
