import { type UserPreferences, userService } from '~/domains/user';
import { type DeepReadonly, type Ref, ref } from 'vue';
import { storeToRefs } from 'pinia';
import useUserStore from '../user.store.ts';
import debounce from 'lodash-es/debounce';

interface ReturnUserReferences {
  changeUserPreferences: (body: UserPreferences) => Promise<void>;
  getUserPreferences: () => Promise<void>;
  preferences: DeepReadonly<Ref<UserPreferences | null>>;
  changePreferencesLoading: Ref<boolean>;
}

export const useUserPreferences = (): ReturnUserReferences => {
  const changePreferencesLoading = ref(false);
  const { preferences } = storeToRefs(useUserStore());

  const getUserPreferences = async (): Promise<void> => {
    await userService.getUserPreferences();
  };

  const debounceFetch = debounce(async (body: UserPreferences) => {
    changePreferencesLoading.value = true;
    try {
      await userService.changeUserPreferences(body);
    } finally {
      changePreferencesLoading.value = false;
    }
  }, 150);

  const changeUserPreferences = async (
    body: UserPreferences,
  ): Promise<void> => {
    debounceFetch(body);
  };

  return {
    changeUserPreferences,
    getUserPreferences,
    preferences,
    changePreferencesLoading,
  };
};
