<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M9.50425 1.33754C9.61364 1.22818 9.76199 1.16675 9.91667 1.16675C10.0713 1.16675 10.2197 1.22818 10.3291 1.33754L12.6624 3.67087C12.7718 3.78026 12.8332 3.92861 12.8332 4.08329C12.8332 4.23797 12.7718 4.38632 12.6624 4.49571L5.07908 12.079C4.96971 12.1884 4.82136 12.2499 4.66667 12.25H2.33333C2.17862 12.25 2.03025 12.1885 1.92085 12.0791C1.81146 11.9697 1.75 11.8213 1.75 11.6666V9.33329C1.75003 9.17859 1.81151 9.03024 1.92092 8.92087L7.75425 3.08754L9.50425 1.33754ZM8.16667 4.32479L2.91667 9.57479V11.0833H4.42517L9.67517 5.83329L8.16667 4.32479ZM10.5 5.00846L11.4252 4.08329L9.91667 2.57479L8.9915 3.49996L10.5 5.00846Z"
      fill="currentColor"
    />
  </svg>
</template>
