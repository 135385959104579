<template>
  <UiDropdown class="header-currency-dropdown">
    <template #activator="{ props }">
      <UiButton
        v-if="selectedCurrency"
        variant="text"
        size="small"
        class="px-sm-2 px-md-3"
        v-bind="props"
        color="white"
        data-test="header-currency-button"
        caret
      >
        <UiText variant="body-4" color="white">
          {{ selectedCurrency }}
        </UiText>
        <template #append>
          <UiIcon icon="mdi-chevron-down" size="small" />
        </template>
      </UiButton>
    </template>
    <UiDropdownItem
      v-for="currency in currencies"
      :key="currency.currency"
      :value="currency.currency"
      :is-active="currency.currency === selectedCurrency"
      data-test="header-currency-item"
      @click="changeCurrency(currency.currency)"
    >
      {{ currency.currency }}
    </UiDropdownItem>
  </UiDropdown>
</template>

<script setup lang="ts">
import { UiDropdown, UiDropdownItem, UiButton, UiText, UiIcon } from '~/ui';
import useChangeCurrency from '~/domains/user/composables/useChangeCurrency.ts';

const { currencies, selectedCurrency, changeCurrency } = useChangeCurrency();
</script>

<style>
.header-currency-dropdown .ui-dropdown__list {
  margin-top: 5px;
}
</style>
