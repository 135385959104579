<template>
  <component :is="tag" :class="classes" :style="styles">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'subtitle-1'
  | 'subtitle-2'
  | 'subtitle-3'
  | 'subtitle-4'
  | 'subtitle-4-5'
  | 'subtitle-5'
  | 'subtitle-5-bold'
  | 'body-1'
  | 'body-2'
  | 'body-3'
  | 'body-4'
  | 'body-4-semi'
  | 'body-5';

type Tag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

export interface Props {
  variant?: Variant;
  color?: string;
  tag?: Tag;
  lineClamp?: number | undefined;
  truncate?: boolean;
  lineHeight?: number | string;
  muted?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  color: undefined,
  variant: 'body-1',
  tag: 'p',
  lineClamp: undefined,
  truncate: false,
  lineHeight: undefined,
  muted: false,
});

const classes = computed(() => [
  `text-${props.variant}`,
  { 'text-truncate': props.truncate },
  props.color !== undefined && `text-${props.color}`,
  props.lineClamp !== undefined && 'ui-text__truncated',
]);
const styles = computed(() => [
  props.lineClamp !== null && `-webkit-line-clamp: ${props.lineClamp};`,
  props.lineHeight !== undefined && `line-height: ${props.lineHeight}px`,
  props.muted && 'opacity: 0.7',
]);
</script>

<style scoped>
.ui-text__truncated {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal !important;
}
</style>
