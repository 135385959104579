import { type ProductAction, type ProductActionResponse } from '../types';
import {
  DEFAULT_PRODUCT_ACTION_COLOR,
  PRODUCT_ACTION_TYPES_MAP,
} from '../constants.d';
import { Price } from '~/utils';
import { dateSerializer } from '~/domains/core';

export default function productActionSerializer(
  data: ProductActionResponse,
): ProductAction {
  return {
    id: data.id,
    name: data.name,
    type: data.type,
    iconType: data.icon_type,
    isIconVisible: data.icon_visible,
    hoverText: data.hover_text,
    color:
      PRODUCT_ACTION_TYPES_MAP[data.icon_type]?.color ??
      DEFAULT_PRODUCT_ACTION_COLOR,
    label: PRODUCT_ACTION_TYPES_MAP[data.icon_type]?.label ?? data.icon_type,
    dateTill: dateSerializer(data.date_till),
    actionProductPriceType: data.action_product_price_type,
    quantity1: data.qty1,
    quantity2: data.qty2,
    quantity3: data.qty3,
    priceFixed1:
      data.price_fixed1 !== undefined ? new Price(data.price_fixed1) : '',
    priceFixed2:
      data.price_fixed2 !== undefined ? new Price(data.price_fixed2) : '',
    priceFixed3:
      data.price_fixed3 !== undefined ? new Price(data.price_fixed3) : '',
    discount1: data.discount1 !== undefined ? new Price(data.discount1) : '',
    discount2: data.discount2 !== undefined ? new Price(data.discount2) : '',
    discount3: data.discount3 !== undefined ? new Price(data.discount3) : '',
    discountPercent1: data.discount_percent1,
    discountPercent2: data.discount_percent2,
    discountPercent3: data.discount_percent3,
    estimatedPrice1:
      data.estimated_price1 !== undefined
        ? new Price(data.estimated_price1)
        : '',
    estimatedPrice2:
      data.estimated_price2 !== undefined
        ? new Price(data.estimated_price2)
        : '',
    estimatedPrice3:
      data.estimated_price3 !== undefined
        ? new Price(data.estimated_price3)
        : '',
  };
}
