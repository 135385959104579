<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon / arrowdown">
      <path
        id="Vector 1"
        d="M6.23178 9.07814L3.36682 5.64018C2.82405 4.98886 3.2872 4 4.13504 4H9.86496C10.7128 4 11.176 4.98886 10.6332 5.64018L7.76822 9.07813C7.36843 9.55789 6.63157 9.55789 6.23178 9.07814Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
