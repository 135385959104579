<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_30682_98410)">
      <path
        d="M14.166 3.33398C14.5865 3.33385 14.9915 3.49266 15.2998 3.77857C15.6081 4.06447 15.797 4.45636 15.8285 4.87565L15.8327 5.00065V6.66732H16.666C17.0865 6.66718 17.4915 6.82599 17.7998 7.1119C18.1081 7.39781 18.297 7.78969 18.3285 8.20898L18.3327 8.33398V15.834C18.3328 16.2545 18.174 16.6595 17.8881 16.9678C17.6022 17.2761 17.2103 17.4649 16.791 17.4965L16.666 17.5007H13.3327C13.0625 17.5007 12.7964 17.4351 12.5573 17.3094C12.3182 17.1836 12.1132 17.0016 11.9602 16.779L11.8893 16.6673H3.33268C2.9122 16.6675 2.50721 16.5086 2.19889 16.2227C1.89057 15.9368 1.70172 15.5449 1.67018 15.1257L1.66602 15.0007V5.00065C1.66588 4.58017 1.82469 4.17518 2.1106 3.86686C2.39651 3.55854 2.78839 3.36969 3.20768 3.33815L3.33268 3.33398H14.166ZM16.666 8.33398H13.3327V15.834H16.666V8.33398ZM14.166 5.00065H3.33268V15.0007H11.666V8.33398C11.666 7.89196 11.8416 7.46803 12.1542 7.15547C12.4667 6.84291 12.8907 6.66732 13.3327 6.66732H14.166V5.00065Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_30682_98410">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
