<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.19083 2.11659C9.43837 1.97912 9.71685 1.90698 10 1.90698C10.2831 1.90698 10.5616 1.97912 10.8092 2.11659L17.0717 5.59492C17.2015 5.66712 17.3097 5.77272 17.385 5.90078C17.4603 6.02883 17.5 6.17469 17.5 6.32325V13.1858C17.4999 13.483 17.4204 13.7747 17.2696 14.0309C17.1188 14.287 16.9023 14.4981 16.6425 14.6424L10.8092 17.8841C10.5616 18.0216 10.2831 18.0937 10 18.0937C9.71685 18.0937 9.43837 18.0216 9.19083 17.8841L3.3575 14.6424C3.0978 14.4982 2.88136 14.2872 2.7306 14.0312C2.57983 13.7753 2.50022 13.4837 2.5 13.1866V6.32325C2.49999 6.17469 2.5397 6.02883 2.61501 5.90078C2.69032 5.77272 2.7985 5.66712 2.92833 5.59492L9.19167 2.11659H9.19083Z"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 5.83325L10 9.99992M10 9.99992L17.0833 6.24992M10 9.99992V17.9166"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linejoin="round"
    />
    <path
      d="M5 10.2733L7.5 11.6667M6.25 7.91667L13.75 3.75L6.25 7.91667Z"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
