<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.85024 3C1.62474 3 1.40848 3.0856 1.24903 3.23798C1.08958 3.39035 1 3.59701 1 3.8125C1 4.02799 1.08958 4.23465 1.24903 4.38702C1.40848 4.5394 1.62474 4.625 1.85024 4.625H2.26856C2.45318 4.62531 2.63269 4.68303 2.77994 4.78945C2.9272 4.89587 3.0342 5.0452 3.08479 5.21487L5.78176 14.2336C5.93429 14.7426 6.25609 15.1902 6.69849 15.5089C7.14089 15.8276 7.67984 16 8.23385 16H16.0034C16.5132 16.0001 17.0114 15.8541 17.4337 15.581C17.8559 15.3078 18.1828 14.92 18.3721 14.4676L20.8786 8.47787C20.9817 8.2314 21.02 7.96457 20.9902 7.70069C20.9604 7.43681 20.8634 7.18392 20.7077 6.96412C20.552 6.74433 20.3424 6.56432 20.0971 6.43983C19.8518 6.31534 19.5783 6.25017 19.3006 6.25H5.16278L4.71895 4.768C4.56681 4.25901 4.2454 3.81118 3.80331 3.49221C3.36123 3.17324 2.8225 3.00046 2.26856 3H1.85024ZM7.41762 13.7851L5.64912 7.875H19.2989L16.7924 13.8647C16.7292 14.0154 16.6203 14.1445 16.4797 14.2354C16.3391 14.3263 16.1732 14.375 16.0034 14.375H8.23385C8.04923 14.3747 7.86973 14.317 7.72247 14.2106C7.57521 14.1041 7.46821 13.9548 7.41762 13.7851Z"
      fill="currentColor"
    />
    <path
      d="M7.5 21C8.32843 21 9 20.3284 9 19.5C9 18.6716 8.32843 18 7.5 18C6.67157 18 6 18.6716 6 19.5C6 20.3284 6.67157 21 7.5 21Z"
      fill="currentColor"
    />
    <path
      d="M16.5 21C17.3284 21 18 20.3284 18 19.5C18 18.6716 17.3284 18 16.5 18C15.6716 18 15 18.6716 15 19.5C15 20.3284 15.6716 21 16.5 21Z"
      fill="currentColor"
    />
  </svg>
</template>
