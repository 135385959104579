<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.5"
      y="3.16699"
      width="11"
      height="3.66667"
      rx="1.5"
      stroke="currentColor"
    />
    <rect
      x="2.5"
      y="9.16699"
      width="11"
      height="3.66667"
      rx="1.5"
      stroke="currentColor"
    />
  </svg>
</template>
