<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
  >
    <path
      d="M4.55 12.2L9.04289 7.70706C9.43342 7.31653 9.43342 6.68337 9.04289 6.29284L4.55 1.79995"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
