<template>
  <UiNavbar id="app-navbar" :left="left" :width="width">
    <AppNavbarSearch />
    <AppNavbarMenu />
  </UiNavbar>
</template>

<script setup lang="ts">
import { UiNavbar } from '~/ui';
import AppNavbarSearch from './AppNavbarSearch.vue';
import AppNavbarMenu from './AppNavbarMenu.vue';
import { useNavbar } from '~/app/layouts/navbar';

const { left, width } = useNavbar();
</script>
