<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.7676 14.9141C16.3992 14.0414 15.8645 13.2486 15.1934 12.5801C14.5243 11.9096 13.7317 11.375 12.8594 11.0059C12.8516 11.002 12.8438 11 12.836 10.9961C14.0528 10.1172 14.8438 8.68555 14.8438 7.07031C14.8438 4.39453 12.6758 2.22656 10 2.22656C7.32427 2.22656 5.1563 4.39453 5.1563 7.07031C5.1563 8.68555 5.94731 10.1172 7.16411 10.998C7.1563 11.002 7.14849 11.0039 7.14067 11.0078C6.26567 11.377 5.48052 11.9062 4.80669 12.582C4.1362 13.2511 3.60162 14.0437 3.23247 14.916C2.86982 15.77 2.67423 16.6856 2.6563 17.6133C2.65578 17.6341 2.65943 17.6549 2.66705 17.6743C2.67467 17.6937 2.6861 17.7114 2.70066 17.7263C2.71522 17.7412 2.73262 17.7531 2.75184 17.7612C2.77105 17.7693 2.7917 17.7734 2.81255 17.7734H3.98442C4.07036 17.7734 4.13872 17.7051 4.14067 17.6211C4.17974 16.1133 4.7852 14.7012 5.85552 13.6309C6.96294 12.5234 8.43364 11.9141 10 11.9141C11.5665 11.9141 13.0372 12.5234 14.1446 13.6309C15.2149 14.7012 15.8204 16.1133 15.8594 17.6211C15.8614 17.707 15.9297 17.7734 16.0157 17.7734H17.1875C17.2084 17.7734 17.229 17.7693 17.2483 17.7612C17.2675 17.7531 17.2849 17.7412 17.2994 17.7263C17.314 17.7114 17.3254 17.6937 17.333 17.6743C17.3407 17.6549 17.3443 17.6341 17.3438 17.6133C17.3243 16.6797 17.1309 15.7715 16.7676 14.9141ZM10 10.4297C9.10356 10.4297 8.25981 10.0801 7.62505 9.44531C6.99028 8.81055 6.64067 7.9668 6.64067 7.07031C6.64067 6.17383 6.99028 5.33008 7.62505 4.69531C8.25981 4.06055 9.10356 3.71094 10 3.71094C10.8965 3.71094 11.7403 4.06055 12.375 4.69531C13.0098 5.33008 13.3594 6.17383 13.3594 7.07031C13.3594 7.9668 13.0098 8.81055 12.375 9.44531C11.7403 10.0801 10.8965 10.4297 10 10.4297Z"
      fill="currentColor"
    />
  </svg>
</template>
