<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2.80078 4.99996C2.80078 4.84084 2.86399 4.68823 2.97652 4.57571C3.08904 4.46318 3.24165 4.39997 3.40077 4.39997C5.53115 4.39997 7.60712 3.64558 9.6407 2.12C9.74456 2.04211 9.87088 2 10.0007 2C10.1305 2 10.2568 2.04211 10.3607 2.12C12.3943 3.64558 14.4702 4.39997 16.6006 4.39997C16.7597 4.39997 16.9124 4.46318 17.0249 4.57571C17.1374 4.68823 17.2006 4.84084 17.2006 4.99996V9.19991C17.2006 13.2007 14.8342 16.1406 10.2207 17.9582C10.0793 18.0139 9.92207 18.0139 9.7807 17.9582C5.16715 16.1406 2.80078 13.1999 2.80078 9.19991V4.99996Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
