<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="list">
      <g id="Vector">
        <path
          d="M2.08331 4.16675L3.33331 5.41675L5.83331 2.91675M2.08331 10.0001L3.33331 11.2501L5.83331 8.75008M2.08331 15.8334L3.33331 17.0834L5.83331 14.5834M8.74998 10.0001H17.9166H8.74998ZM8.74998 15.8334H17.9166H8.74998ZM8.74998 4.16675H17.9166H8.74998Z"
          fill="currentColor"
        />
        <path
          d="M2.08331 4.16675L3.33331 5.41675L5.83331 2.91675M2.08331 10.0001L3.33331 11.2501L5.83331 8.75008M2.08331 15.8334L3.33331 17.0834L5.83331 14.5834M8.74998 10.0001H17.9166M8.74998 15.8334H17.9166M8.74998 4.16675H17.9166"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
</template>
