import { type ComputedRef, computed } from 'vue';
import useUserStore from '../user.store';
interface ReturnUseAvailableCredit {
  availableCreditContent: ComputedRef<string>;
  isCreditLimit: ComputedRef<boolean>;
  overdueIsMoreZero: ComputedRef<boolean>;
}

export const useAvailableCredit = (): ReturnUseAvailableCredit => {
  const { user } = useUserStore();

  const isCreditLimit = computed(() => user?.credit?.creditLimit != null);

  const overdueIsMoreZero = computed(() => {
    const overdue = user?.credit?.overdue;

    if (overdue === undefined) return false;

    return parseFloat(String(overdue.amount)) > 0;
  });

  const creditLimitIsMoreZero = computed(() => {
    const creditLimit = user?.credit?.creditLimit;

    if (creditLimit === undefined) return false;

    return parseFloat(String(creditLimit.amount)) > 0;
  });

  const availableCreditContent = computed(() => {
    if (overdueIsMoreZero.value) {
      return String(user?.credit?.overdue.amount) ?? '0.00';
    } else if (creditLimitIsMoreZero.value) {
      return String(user?.credit?.creditLimit.amount) ?? '0.00';
    }

    return '0.00';
  });

  return {
    availableCreditContent,
    isCreditLimit,
    overdueIsMoreZero,
  };
};
