<template>
  <VTextField
    ref="input"
    :model-value="modelValue"
    :label="label"
    :placeholder="placeholder"
    :type="type"
    :base-color="baseColor"
    :readonly="readonly"
    :density="density"
    :variant="variant"
    :single-line="singleLine"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :disabled="disabled"
    :clearable="clearable"
    :bg-color="bgColor"
    :active="active"
    :inputmode="inputmode"
    :hint="hint"
    :persistent-hint="persistentHint"
    :max-width="maxWidth"
    :min-width="minWidth"
    :width
    :rounded
    class="field"
    @update:model-value="$emit('update:modelValue', $event)"
    @focus="handleFocus"
    @blur="handleBlur"
    @mouseup="handleFocus"
  >
    <template #append-inner>
      <slot name="append-inner" />
    </template>
    <template #prepend-inner>
      <slot name="prepend-inner" />
    </template>
  </VTextField>
</template>

<script setup lang="ts">
import { ref, nextTick } from 'vue';
import { VTextField } from 'vuetify/components/VTextField';
import { type Rounded, type Density } from '~/infrastructure';

export interface Props {
  modelValue: any;
  label?: string;
  placeholder?: string;
  type?: string;
  baseColor?: string;
  readonly?: boolean;
  density?: Density;
  singleLine?: boolean;
  hideDetails?: 'auto' | boolean;
  disabled?: boolean;
  clearable?: boolean;
  bgColor?: string;
  errorMessages?: string | string[];
  active?: boolean;
  variant?:
    | 'outlined'
    | 'plain'
    | 'underlined'
    | 'filled'
    | 'solo'
    | 'solo-inverted'
    | 'solo-filled';
  inputmode?: string;
  selectOnFocus?: boolean;
  hint?: string;
  persistentHint?: boolean;
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  rounded?: Rounded;
}
const props = withDefaults(defineProps<Props>(), {
  baseColor: 'grey-400',
  label: '',
  placeholder: '',
  type: 'text',
  variant: 'outlined',
  density: 'default',
  singleLine: false,
  hideDetails: false,
  clearable: false,
  bgColor: 'white',
  active: false,
  disabled: false,
  errorMessages: '',
  inputmode: 'text',
  selectOnFocus: false,
  hint: '',
  persistentHint: false,
  width: undefined,
  maxWidth: undefined,
  minWidth: undefined,
  rounded: 'xl',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'focus', value: Event): void;
  (e: 'blur', value: Event): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
}>();

const input = ref();

const focus = (): void => {
  nextTick(() => {
    input.value.focus();
  });
};

const blur = (): void => {
  nextTick(() => {
    input.value.blur();
  });
};

const handleFocus = (event: Event): void => {
  const target = event.target as HTMLInputElement | HTMLTextAreaElement;
  if (
    target != null &&
    props.selectOnFocus &&
    typeof target.select === 'function'
  ) {
    setTimeout(() => {
      target.select();
    }, 0);
  }

  emit('focus');
};

const handleBlur = (): void => {
  emit('blur');
};

defineExpose({
  focus,
  blur,
});
</script>

<style lang="scss">
.field {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input:-moz-ui-valid {
    box-shadow: 0 0 0 30px white inset !important;
  }
}
</style>

<style lang="scss" scoped>
.field {
  font-size: 14px;

  :deep(.v-field__input) {
    font-size: 14px !important;
  }
}

:deep(
    .v-field:not(.v-field--focused, .v-field--dirty) .v-field__field .v-label
  ) {
  font-size: 14px;
}

:deep(.v-label) {
  opacity: 1;
  color: rgb(var(--v-theme-grey-800));
}

:deep(.v-field__input) {
  color: rgb(var(--v-theme-black));
  ::placeholder {
    color: rgb(var(--v-theme-grey-800));
    opacity: 1;
  }
}

:deep(.v-field:not(.v-field--error)) {
  .v-field__outline {
    .v-field__outline__start,
    .v-field__outline__notch::before,
    .v-field__outline__notch::after,
    .v-field__outline__end {
      border-color: rgb(var(--v-theme-fours-grey-200));
      opacity: 1;
    }
  }
}

:deep(.v-field--active.v-field--variant-outlined) {
  .v-field__outline {
    .v-field__outline__notch::before {
      opacity: 0;
    }
  }
}

:deep(.v-sheet) {
  color: rgb(var(--v-theme-fours-grey-400));
}

:deep(.v-input__details) {
  padding-top: var(--spacing-base);
  padding-left: var(--spacing-3);
}

:deep(.v-messages) {
  .v-messages__message {
    white-space: pre-line;
  }
}

:deep(input) {
  -webkit-appearance: none;
}
</style>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
