<template>
  <UiTooltip v-model="isOpen" :open-on-hover="false" :open-on-click="true">
    <template #activator="slotProps">
      <UiButton
        v-bind="slotProps.props"
        icon="$copy"
        size="x-small"
        class="rounded-md"
        height="24"
        width="24"
        :class="currentColor"
        :color="bgColor"
        data-html2canvas-ignore
        @click="copyToClipboard"
      >
      </UiButton>
    </template>
    Copied <UiIcon icon="$check" color="grey-800" />
  </UiTooltip>
</template>
<script setup lang="ts">
import { UiIcon, UiButton } from '~/ui';
import UiTooltip from '../UiTooltip/UiTooltip.vue';

import { ref, computed } from 'vue';

export interface Props {
  copy: string;
  color?: string;
  bgColor?: string;
}
const props = withDefaults(defineProps<Props>(), {
  color: 'grey-400',
  bgColor: 'transparent',
});

const isOpen = ref(false);
const tooltipDelay = 2000;
const currentColor = computed(() => `text-${props.color}`);

const closeTooltip = (): void => {
  setTimeout(() => {
    isOpen.value = !isOpen.value;
  }, tooltipDelay);
};

const copyToClipboard = (): void => {
  navigator.clipboard.writeText(props.copy.trim());
  closeTooltip();
};
</script>
