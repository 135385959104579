<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_18696_59606)">
      <path
        d="M0.430058 9.88029C0.429589 11.5607 0.871977 13.2014 1.71317 14.6477L0.349609 19.5889L5.44457 18.263C6.85376 19.0244 8.43267 19.4234 10.0371 19.4235H10.0414C15.3381 19.4235 19.6497 15.1457 19.652 9.88781C19.653 7.33998 18.6542 4.94416 16.8395 3.14168C15.0251 1.33936 12.612 0.346256 10.041 0.345093C4.74365 0.345093 0.432323 4.62261 0.430136 9.88029"
        fill="url(#paint0_linear_18696_59606)"
      />
      <path
        d="M0.0855257 9.87721C0.0849789 11.6181 0.543222 13.3175 1.41441 14.8155L0.00195312 19.9339L5.2796 18.5605C6.73376 19.3474 8.371 19.7622 10.037 19.7629H10.0413C15.528 19.7629 19.9945 15.3312 19.9969 9.88512C19.9978 7.24574 18.9631 4.7638 17.0836 2.89674C15.2038 1.02992 12.7044 0.00108527 10.0413 0C4.55361 0 0.0877126 4.43101 0.0855257 9.87721ZM3.22848 14.5575L3.03142 14.2471C2.20303 12.9398 1.7658 11.4291 1.76643 9.87783C1.76822 5.35109 5.48009 1.66822 10.0444 1.66822C12.2548 1.66915 14.3321 2.52434 15.8945 4.07597C17.4568 5.62775 18.3165 7.69054 18.316 9.8845C18.3139 14.4112 14.602 18.0946 10.0413 18.0946H10.038C8.55299 18.0938 7.09656 17.698 5.82641 16.95L5.52414 16.7721L2.39228 17.5871L3.22848 14.5574V14.5575Z"
        fill="url(#paint1_linear_18696_59606)"
      />
      <path
        d="M7.55286 5.74789C7.3665 5.3368 7.17038 5.32851 6.99316 5.3213C6.84804 5.31509 6.68215 5.31556 6.51641 5.31556C6.35051 5.31556 6.08097 5.3775 5.85314 5.6244C5.62507 5.87153 4.98242 6.46874 4.98242 7.68339C4.98242 8.89812 5.87384 10.072 5.9981 10.2369C6.12252 10.4015 7.71899 12.9739 10.2474 13.9635C12.3488 14.7859 12.7764 14.6223 13.2324 14.5811C13.6886 14.54 14.7043 13.984 14.9115 13.4075C15.1188 12.8311 15.1188 12.337 15.0567 12.2337C14.9945 12.1308 14.8286 12.069 14.5798 11.9456C14.331 11.8221 13.108 11.2249 12.88 11.1425C12.652 11.0601 12.4861 11.019 12.3202 11.2663C12.1544 11.5131 11.678 12.069 11.5328 12.2337C11.3877 12.3987 11.2426 12.4193 10.9939 12.2958C10.7449 12.1719 9.94382 11.9115 8.99336 11.0705C8.25386 10.4161 7.75461 9.60796 7.60949 9.36075C7.46437 9.11393 7.59395 8.98013 7.71868 8.85711C7.83045 8.74649 7.96752 8.56882 8.09202 8.42471C8.21605 8.28052 8.25745 8.17765 8.3404 8.013C8.42342 7.8482 8.38187 7.70401 8.31978 7.58052C8.25745 7.45703 7.77406 6.23603 7.55286 5.74789Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_18696_59606"
        x1="965.468"
        y1="1924.73"
        x2="965.468"
        y2="0.345093"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1FAF38" />
        <stop offset="1" stop-color="#60D669" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_18696_59606"
        x1="999.748"
        y1="1993.39"
        x2="999.748"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F9F9F9" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <clipPath id="clip0_18696_59606">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
