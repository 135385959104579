<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <g clip-path="url(#clip0_22108_54136)">
      <path
        d="M4.85742 3.5H10.8574H16.8574H4.85742ZM7.85742 9.5L4.85742 3.5L1.85742 9.5C1.85742 10.2956 2.17349 11.0587 2.7361 11.6213C3.29871 12.1839 4.06177 12.5 4.85742 12.5C5.65307 12.5 6.41613 12.1839 6.97874 11.6213C7.54135 11.0587 7.85742 10.2956 7.85742 9.5ZM19.8574 9.5L16.8574 3.5L13.8574 9.5C13.8574 10.2956 14.1735 11.0587 14.7361 11.6213C15.2987 12.1839 16.0618 12.5 16.8574 12.5C17.6531 12.5 18.4161 12.1839 18.9787 11.6213C19.5414 11.0587 19.8574 10.2956 19.8574 9.5Z"
        fill="currentColor"
      />
      <path
        d="M5.85742 17.5H15.8574M4.85742 3.5H10.8574H16.8574M4.85742 3.5H16.8574M4.85742 3.5L7.85742 9.5C7.85742 10.2956 7.54135 11.0587 6.97874 11.6213C6.41613 12.1839 5.65307 12.5 4.85742 12.5C4.06177 12.5 3.29871 12.1839 2.7361 11.6213C2.17349 11.0587 1.85742 10.2956 1.85742 9.5L4.85742 3.5ZM16.8574 3.5L19.8574 9.5C19.8574 10.2956 19.5414 11.0587 18.9787 11.6213C18.4161 12.1839 17.6531 12.5 16.8574 12.5C16.0618 12.5 15.2987 12.1839 14.7361 11.6213C14.1735 11.0587 13.8574 10.2956 13.8574 9.5L16.8574 3.5ZM10.8574 2V17.5"
        stroke="currentColor"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_22108_54136">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.857422)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
