<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon">
      <path
        id="Vector"
        d="M17.5556 2H6.44444C3.98985 2 2 3.98985 2 6.44444V17.5556C2 20.0102 3.98985 22 6.44444 22H17.5556C20.0102 22 22 20.0102 22 17.5556V6.44444C22 3.98985 20.0102 2 17.5556 2Z"
        fill="#1C4D88"
      />
      <path
        id="Vector_2"
        d="M16.7907 8.23478C17.0698 8.54731 17.0698 9.05237 16.7907 9.3649L11.0761 15.7656C10.7971 16.0781 10.3462 16.0781 10.0671 15.7656L7.20921 12.5652C6.93026 12.2527 6.93026 11.7477 7.20921 11.4351C7.4882 11.1226 7.94045 11.1226 8.21949 11.4351L10.5515 14.0679L15.7817 8.23478C16.0608 7.92174 16.5117 7.92174 16.7907 8.23478Z"
        fill="white"
      />
    </g>
  </svg>
</template>
