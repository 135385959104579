import {
  type CatalogSortBy,
  type CategorySortQuery,
  type CategorySortTypes,
} from '~/domains/category';

const getSortType = (data: CatalogSortBy): CategorySortTypes => {
  switch (data.field) {
    case 'sku': {
      return data.sort === 'asc' ? 'ARTICLE_ASC' : 'ARTICLE_DESC';
    }
    case 'title': {
      return data.sort === 'asc' ? 'DESCRIPTION_ASC' : 'DESCRIPTION_DESC';
    }
    case 'price': {
      return data.sort === 'asc' ? 'PRICE_ASC' : 'PRICE_DESC';
    }
    case 'stock': {
      return data.sort === 'asc' ? 'STOCK_LTE_ASC' : 'STOCK_LTE_DESC';
    }
  }
};

export default function productSortDeserializer(
  data: CatalogSortBy | undefined,
): CategorySortQuery | null {
  if (data !== undefined) {
    return {
      sb: getSortType(data),
    };
  }

  return null;
}
