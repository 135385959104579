<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5 15C4.71667 15 4.479 14.904 4.287 14.712C4.095 14.52 3.99934 14.2827 4 14C4 13.7167 4.096 13.479 4.288 13.287C4.48 13.095 4.71734 12.9993 5 13H19C19.2833 13 19.521 13.096 19.713 13.288C19.905 13.48 20.0007 13.7173 20 14C20 14.2833 19.904 14.521 19.712 14.713C19.52 14.905 19.2827 15.0007 19 15H5ZM5 11C4.71667 11 4.479 10.904 4.287 10.712C4.095 10.52 3.99934 10.2827 4 10C4 9.71667 4.096 9.479 4.288 9.287C4.48 9.095 4.71734 8.99934 5 9H19C19.2833 9 19.521 9.096 19.713 9.288C19.905 9.48 20.0007 9.71734 20 10C20 10.2833 19.904 10.521 19.712 10.713C19.52 10.905 19.2827 11.0007 19 11H5Z"
      fill="currentColor"
    />
  </svg>
</template>
