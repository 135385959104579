<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5005 1.1875C7.64842 1.1875 5.32812 3.28968 5.32812 5.87349V9.60508C5.32812 12.1889 7.64842 14.2909 10.5005 14.2909C13.3525 14.2909 15.673 12.1953 15.673 9.61942V5.87349C15.673 3.28968 13.3525 1.1875 10.5005 1.1875ZM14.7001 9.61942C14.7001 11.7168 12.8163 13.4232 10.5005 13.4232C8.18495 13.4232 6.30101 11.7103 6.30101 9.60496V5.87349C6.30101 3.76811 8.18495 2.05527 10.5005 2.05527C12.8163 2.05527 14.7001 3.76811 14.7001 5.87349V9.61942Z"
      fill="currentColor"
    />
    <path
      d="M10.5001 4.77539C10.2314 4.77539 10.0137 4.96964 10.0137 5.20929V6.72791C10.0137 6.96756 10.2314 7.16181 10.5001 7.16181C10.7688 7.16181 10.9866 6.96756 10.9866 6.72791V5.20929C10.9866 4.96964 10.7688 4.77539 10.5001 4.77539Z"
      fill="currentColor"
    />
    <path
      d="M6.36133 16.4982L9.67127 19.1462C10.1553 19.5335 10.8432 19.5335 11.3272 19.1462L14.6372 16.4982"
      stroke="currentColor"
      stroke-width="1.025"
      stroke-linecap="round"
    />
  </svg>
</template>
