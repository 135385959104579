<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0002 16.6666V9.99998M10.0002 9.99998V3.33331M10.0002 9.99998H16.6668M10.0002 9.99998H3.3335"
      stroke="currentColor"
      stroke-width="1.6"
      stroke-linecap="round"
    />
  </svg>
</template>
