<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="clip">
      <path
        id="Vector"
        d="M12.0142 3.44272C11.5134 2.94474 10.8366 2.6641 10.1303 2.66161C9.42403 2.65911 8.74522 2.93496 8.24089 3.42939L3.80089 7.79206C3.675 7.91601 3.50513 7.98503 3.32846 7.98404C3.15179 7.98304 2.98271 7.91209 2.85822 7.78672C2.7967 7.7247 2.74804 7.65112 2.71506 7.57022C2.68207 7.48932 2.66541 7.4027 2.66603 7.31534C2.66665 7.22797 2.68454 7.1416 2.71867 7.06117C2.7528 6.98075 2.80249 6.90787 2.86489 6.84672L7.30356 2.48539C8.05874 1.74412 9.07559 1.33027 10.1338 1.33352C11.192 1.33676 12.2063 1.75684 12.9569 2.50272C13.3293 2.87286 13.6248 3.31297 13.8264 3.79773C14.0281 4.2825 14.1319 4.80235 14.1319 5.32739C14.1319 5.85243 14.0281 6.37228 13.8264 6.85705C13.6248 7.34181 13.3293 7.78192 12.9569 8.15206L7.18089 13.9041C6.68976 14.3923 6.02539 14.6663 5.33289 14.6663C4.64039 14.6663 3.97603 14.3923 3.48489 13.9041C3.24232 13.6628 3.04983 13.376 2.91848 13.0601C2.78713 12.7442 2.71951 12.4055 2.71951 12.0634C2.71951 11.7213 2.78713 11.3825 2.91848 11.0667C3.04983 10.7508 3.24232 10.464 3.48489 10.2227L8.87156 4.85872C8.99669 4.73447 9.16588 4.66475 9.34223 4.66475C9.51857 4.66475 9.68776 4.73447 9.81289 4.85872C9.87465 4.92029 9.92366 4.99343 9.95709 5.07397C9.99053 5.15451 10.0077 5.24085 10.0077 5.32806C10.0077 5.41526 9.99053 5.50161 9.95709 5.58214C9.92366 5.66268 9.87465 5.73583 9.81289 5.79739L4.42756 11.1614C4.30867 11.2796 4.21433 11.4201 4.14995 11.5749C4.08557 11.7297 4.05243 11.8957 4.05243 12.0634C4.05243 12.231 4.08557 12.397 4.14995 12.5518C4.21433 12.7066 4.30867 12.8472 4.42756 12.9654C4.66825 13.2054 4.9943 13.3402 5.33422 13.3402C5.67415 13.3402 6.0002 13.2054 6.24089 12.9654L12.0142 7.21339C12.2627 6.96631 12.4599 6.67255 12.5945 6.349C12.729 6.02544 12.7983 5.67848 12.7983 5.32806C12.7983 4.97764 12.729 4.63067 12.5945 4.30712C12.4599 3.98356 12.2627 3.6898 12.0142 3.44272Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
