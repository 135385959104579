import { authService, PATH_DICTIONARY } from '~/domains/auth';
import { useGetTranslates } from '~/domains/i18n';
import { useRouter } from '~/infrastructure';

interface UseLogoutReturn {
  logout: () => Promise<void>;
}

export default function useLogout(): UseLogoutReturn {
  const router = useRouter();
  const { getTranslates } = useGetTranslates();

  const logout = async (): Promise<void> => {
    await authService.logout();
    router.push(PATH_DICTIONARY.login);
    getTranslates();
  };

  return {
    logout,
  };
}
