import { ofetch } from 'ofetch';

const API_URL = import.meta.env.VITE_API_URL;

const api = ofetch.create({
  baseURL: API_URL,
  onRequest: ({ options }) => {
    const prevUrl =
      window.history.state.back !== null
        ? `${window.location.origin}${window.history.state.back}`
        : '';

    const authToken = localStorage.getItem('authToken');

    const newHeaders: Record<string, string> = {
      'x-original-location': window.location.href,
      'x-original-referer': prevUrl,
      ...(authToken !== '' &&
        typeof authToken === 'string' && { Authorization: authToken }),
    };

    if (options?.headers === undefined) {
      options.headers = newHeaders;
    } else {
      const headers = new Headers(options.headers);

      for (const [key, value] of Object.entries(newHeaders)) {
        headers.set(key, value);
      }
    }
  },
  onResponseError: async (error) => {
    if (
      error.response.status === 403 &&
      window.location.pathname !== '/login'
    ) {
      localStorage.removeItem('authToken');
      window.location.href = '/login';
    }

    await Promise.reject(error);
  },
});

export default api;
