<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon / arrowdown">
      <path
        id="Vector 1"
        d="M7.76822 4.92186L10.6332 8.35982C11.176 9.01114 10.7128 10 9.86496 10H4.13504C3.2872 10 2.82405 9.01114 3.36682 8.35982L6.23178 4.92187C6.63157 4.44211 7.36843 4.44211 7.76822 4.92186Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
