import { getSocialsUseCase } from '../../useCases';
import { socialsLocalRepository } from '../repositories';
import { type Social } from '../../entities';

const useGetSocialsAdapter = async (): Promise<Social[] | undefined> => {
  return await getSocialsUseCase({
    socialsRepository: socialsLocalRepository,
  });
};

export default useGetSocialsAdapter;
