<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon / check">
      <path
        id="Combined-Shape"
        d="M12.9078 5.45825C13.2534 5.12438 13.2534 4.58427 12.9078 4.2504C12.5622 3.91653 12.0031 3.91653 11.6575 4.2504L6.68457 9.0533L4.50951 6.95438C4.1639 6.62051 3.60481 6.62051 3.2592 6.95438C2.9136 7.28825 2.9136 7.82836 3.2592 8.16223L6.05941 10.8662C6.23163 11.0337 6.45928 11.1169 6.68457 11.1169C6.91104 11.1169 7.13751 11.0337 7.30972 10.8662L12.9078 5.45825Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
