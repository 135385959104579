<template>
  <UiRow no-gutters class="px-3 mt-0 pl-lg-3 pl-sm-0" align="center">
    <UiCol class="px-1" cols="auto">
      <UiText variant="body-4" color="grey-400">
        <AppTranslate :identifier="label" />:
      </UiText>
    </UiCol>
    <UiCol v-if="items?.length > 1" class="px-0">
      <UiButton
        variant="text"
        size="small"
        color="white"
        caret
        style="width: fit-content"
        class="app-header-layout-button"
        @click="$emit('click')"
      >
        <AppHeaderLayoutLabel :country="countryByCode" :label="title" />

        <template #append>
          <UiIcon icon="$mdi-chevron-down" />
        </template>
      </UiButton>
    </UiCol>
    <UiCol v-else cols="auto" class="px-1">
      <AppHeaderLayoutLabel :country="countryByCode" :label="title" />
    </UiCol>
  </UiRow>
</template>

<script setup lang="ts">
import { UiRow, UiCol, UiText, UiButton, UiIcon } from '~/ui';
import { AppTranslate } from '~/infrastructure';
import { computed } from 'vue';
import AppHeaderLayoutLabel from '~/app/layouts/header/components/AppHeaderLayoutLabel.vue';
import useGetCountryWithFlagViewModel from '~/domains/core/ui/viewModels/useGetCountryWithFlagViewModel.ts';

export interface Props {
  label: string;
  item: string;
  items: any[];
  countryCode: string;
  city?: string;
}

const props = defineProps<Props>();

defineEmits<(e: 'click') => void>();

const { countryWithFlag } = useGetCountryWithFlagViewModel();

const countryByCode = computed(() => {
  return countryWithFlag.value?.find(
    (country) => country.countryCode === props.countryCode,
  );
});

const title = computed(() => {
  return props.city !== undefined ? `${props.item}, ${props.city}` : props.item;
});
</script>

<style scoped>
.app-header-layout-button {
  padding: 0 4px;

  @media (max-width: 1024px) {
    height: auto !important;
  }
}
</style>
