<template>
  <img
    :src="resizedSrc"
    :alt="alt"
    :style="styles"
    :width="width"
    :height="height"
    :loading="loading"
    @error="setDefaultBannerImage"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';

export interface Props {
  src: string;
  aspectRatio?: string | number | 'auto';
  contain?: boolean;
  alt: string;
  minWidth?: string | number;
  maxWidth?: string | number;
  objectFit?: 'none' | 'cover' | 'contain';
  width: number | string;
  height: number | string;
  loading?: 'lazy' | 'eager';
}

const props = withDefaults(defineProps<Props>(), {
  aspectRatio: 'auto',
  minWidth: 'auto',
  maxWidth: 'auto',
  objectFit: 'contain',
  loading: 'lazy',
});

const styles = computed(() => ({
  aspectRatio: props.aspectRatio,
  objectFit: props.objectFit,
  'min-width':
    props.minWidth !== 'auto' ? `${props.minWidth}px` : props.minWidth,
  'max-width':
    props.maxWidth !== 'auto' ? `${props.maxWidth}px` : props.maxWidth,
}));

const defaultProductImage = '/images/product-image-default.svg';

const resizedSrc = computed(() =>
  props.src !== undefined
    ? props.src?.replace(
        /RESIZE=[0-9]+x[0-9]+/,
        `RESIZE=${props.width?.toString()}x${props.height?.toString()}`,
      )
    : defaultProductImage,
);

const setDefaultBannerImage = (e: Event): void => {
  if (e.target === null) return;
  e.target.src = defaultProductImage;
};
</script>
