<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon / arrow">
      <path
        id="Vector"
        d="M15.9375 9.0625L10.3125 3.4375M10.3125 3.4375L4.6875 9.0625M10.3125 3.4375L10.3125 16.5625"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
