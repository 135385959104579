<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.1667 16.0167H4.16667V5.83335H9.16667C9.6269 5.83335 10 5.46026 10 5.00002C10 4.53978 9.6269 4.16669 9.16667 4.16669H4.16667C3.25 4.16669 2.5 4.91669 2.5 5.83335V15.8334C2.5 16.75 3.25 17.5 4.16667 17.5H14.1667C15.0833 17.5 15.8333 16.75 15.8333 15.8334V10.8334C15.8333 10.3731 15.4602 10 15 10C14.5398 10 14.1667 10.3731 14.1667 10.8334V16.0167Z"
      fill="currentColor"
    />
    <path
      d="M15.8335 2.50002C15.8335 2.03978 15.4604 1.66669 15.0002 1.66669C14.5399 1.66669 14.1668 2.03978 14.1668 2.50002V4.16669H12.5002C12.0399 4.16669 11.6668 4.53978 11.6668 5.00002C11.6668 5.46026 12.0399 5.83335 12.5002 5.83335H14.1668V7.49169C14.1668 7.95192 14.5399 8.32502 15.0002 8.32502C15.4604 8.32502 15.8335 7.95192 15.8335 7.49169V5.83335H17.5002C17.9604 5.83335 18.3335 5.46026 18.3335 5.00002C18.3335 4.53978 17.9604 4.16669 17.5002 4.16669H15.8335V2.50002ZM5.8335 8.25004C5.8335 7.83582 6.16929 7.50002 6.58352 7.50002H11.7501C12.1644 7.50002 12.5002 7.83582 12.5002 8.25004C12.5002 8.66427 12.1644 9.00006 11.7501 9.00006H6.58352C6.16929 9.00006 5.8335 8.66427 5.8335 8.25004ZM6.58352 10C6.16929 10 5.8335 10.3358 5.8335 10.75C5.8335 11.1643 6.16929 11.5001 6.58352 11.5001H11.7501C12.1644 11.5001 12.5002 11.1643 12.5002 10.75C12.5002 10.3358 12.1644 10 11.7501 10H10.0002H6.58352ZM5.8335 13.25C5.8335 12.8358 6.16929 12.5 6.58352 12.5H11.7501C12.1644 12.5 12.5002 12.8358 12.5002 13.25C12.5002 13.6643 12.1644 14.0001 11.7501 14.0001H6.58352C6.16929 14.0001 5.8335 13.6643 5.8335 13.25Z"
      fill="currentColor"
    />
  </svg>
</template>
