<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 16.8091H2.75H3.5ZM3.5 1V0.25C3.08579 0.25 2.75 0.585786 2.75 1L3.5 1ZM12.3926 1L12.923 0.46967C12.7823 0.329018 12.5916 0.25 12.3926 0.25V1ZM17.333 5.94036H18.083C18.083 5.74145 18.004 5.55068 17.8633 5.41003L17.333 5.94036ZM11.1665 10.8807C11.1665 10.4665 10.8307 10.1307 10.4165 10.1307C10.0023 10.1307 9.6665 10.4665 9.6665 10.8807H11.1665ZM9.6665 14.833C9.6665 15.2472 10.0023 15.583 10.4165 15.583C10.8307 15.583 11.1665 15.2472 11.1665 14.833H9.6665ZM8.44036 12.1069C8.02615 12.1069 7.69036 12.4426 7.69036 12.8569C7.69036 13.2711 8.02615 13.6069 8.44036 13.6069V12.1069ZM12.3926 13.6069C12.8069 13.6069 13.1426 13.2711 13.1426 12.8569C13.1426 12.4426 12.8069 12.1069 12.3926 12.1069V13.6069ZM11.4046 6.92843H10.6546C10.6546 7.34264 10.9904 7.67843 11.4046 7.67843V6.92843ZM5.47614 18.0353C5.15095 18.0353 4.83908 17.9061 4.60913 17.6762L3.54847 18.7368C4.05972 19.2481 4.75313 19.5353 5.47614 19.5353V18.0353ZM4.60913 17.6762C4.37918 17.4462 4.25 17.1343 4.25 16.8091H2.75C2.75 17.5322 3.03722 18.2256 3.54847 18.7368L4.60913 17.6762ZM4.25 16.8091V1H2.75V16.8091H4.25ZM3.5 1.75H12.3926V0.25H3.5V1.75ZM11.8623 1.53033L16.8027 6.47069L17.8633 5.41003L12.923 0.46967L11.8623 1.53033ZM16.583 5.94036V16.8091H18.083V5.94036H16.583ZM16.583 16.8091C16.583 17.1343 16.4538 17.4462 16.2239 17.6762L17.2845 18.7368C17.7958 18.2256 18.083 17.5322 18.083 16.8091H16.583ZM16.2239 17.6762C15.9939 17.9061 15.6821 18.0353 15.3569 18.0353V19.5353C16.0799 19.5353 16.7733 19.2481 17.2845 18.7368L16.2239 17.6762ZM15.3569 18.0353H5.47614V19.5353H15.3569V18.0353ZM9.6665 10.8807V14.833H11.1665V10.8807H9.6665ZM8.44036 13.6069H12.3926V12.1069H8.44036V13.6069ZM10.6546 1V6.92843H12.1546V1H10.6546ZM11.4046 7.67843H17.333V6.17843H11.4046V7.67843Z"
      fill="currentColor"
    />
  </svg>
</template>
