<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 22C6.48571 22 2 17.5143 2 12C2 6.48571 6.48571 2 12 2C17.5143 2 22 6.48571 22 12C22 17.5143 17.5143 22 12 22ZM12 3.42857C7.27143 3.42857 3.42857 7.27143 3.42857 12C3.42857 16.7286 7.27143 20.5714 12 20.5714C16.7286 20.5714 20.5714 16.7286 20.5714 12C20.5714 7.27143 16.7286 3.42857 12 3.42857Z"
      fill="currentColor"
    />
    <path
      d="M15.1433 16C14.989 16 14.8347 15.9692 14.6976 15.8923L10.4115 13.5846C10.2846 13.5162 10.1798 13.4191 10.1076 13.303C10.0354 13.187 9.99835 13.056 10.0001 12.9231V6.76923C10.0001 6.33846 10.3772 6 10.8573 6C11.3373 6 11.7145 6.33846 11.7145 6.76923V12.4923L15.5891 14.5692C15.7487 14.657 15.872 14.7891 15.9405 14.9454C16.0089 15.1018 16.0186 15.2739 15.9682 15.4356C15.9178 15.5974 15.81 15.74 15.6612 15.8419C15.5123 15.9437 15.3305 15.9992 15.1433 16Z"
      fill="currentColor"
    />
  </svg>
</template>
