<template>
  <VChip
    ref="chipRef"
    :model-value="modelValue"
    :size="size"
    :to="to"
    :rounded="rounded"
    :class="classes"
    :variant="variant"
    :closable="closable"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    :disabled="disabled"
    style="transition: all 0.2s"
    @update:model-value="$emit('update:modelValue', $event)"
    @click:close="$emit('clickClose')"
  >
    <template #close>
      <UiSheet
        v-if="closable"
        rounded="pill"
        :color="isHovered ? 'blue-500' : 'transparent'"
        width="16"
        height="16"
        class="d-flex align-center justify-center"
        style="transition: all 0.2s"
      >
        <UiIcon
          icon="$close"
          size="12"
          :color="isHovered ? 'white' : 'grey-600'"
          style="transition: all 0.2s"
        />
      </UiSheet>
    </template>
    <slot></slot>
  </VChip>
</template>

<script setup lang="ts">
import { VChip } from 'vuetify/components/VChip';
import { UiIcon, UiSheet } from '~/ui';
import { computed, ref } from 'vue';
import { useElementHover } from '@vueuse/core';

export type Size = 'x-small' | 'small' | 'default' | 'large' | 'x-large';

export interface Props {
  modelValue?: any;
  textColor?: string;
  bgColor?: string;
  size?: Size;
  to?: string;
  rounded?: string | boolean;
  closable?: boolean;
  variant?: 'text' | 'flat' | 'elevated' | 'tonal' | 'outlined' | 'plain';
  appendIcon?: string;
  prependIcon?: string;
  disableTextHover?: boolean;
  disabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  size: 'default',
  textColor: 'black',
  bgColor: 'grey-50',
  variant: 'tonal',
  to: undefined,
  rounded: undefined,
  closable: false,
  appendIcon: undefined,
  prependIcon: undefined,
  disableTextHover: true,
  modelValue: undefined,
  disabled: false,
});
defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'clickClose'): void;
}>();

const chipRef = ref();
const isHovered = useElementHover(chipRef);
const classes = computed(
  () =>
    `bg-${props.bgColor} text-${
      !props.disableTextHover && isHovered.value
        ? 'blue-500 text-decoration-line-through'
        : props.textColor
    }`,
);
</script>

<style lang="scss" scoped>
:deep(.v-chip__underlay),
:deep(.v-chip__overlay) {
  color: transparent;
}

:deep(.v-chip__close),
:deep(.v-chip__append) {
  color: rgb(var(--v-theme-primary));
}

:deep(.v-chip__append .v-icon) {
  width: 14px;
}

:deep(.text-color__white) {
  color: rgb(var(--v-theme-white)) !important;
}
</style>
