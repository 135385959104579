<template>
  <AppHeader />
  <AppSidebar />
  <AppNavbar />
  <UiMain>
    <ErrorPage v-if="error" v-bind="error" />
    <RouterView v-else :key="route.path" />
    <template #footer>
      <AppFooter />
    </template>
  </UiMain>
</template>

<script setup lang="ts">
import { UiMain } from '~/ui';
import { AppFooter } from '~/app/layouts/footer';
import { AppHeader } from '~/app/layouts//header';
import { AppNavbar } from '~/app/layouts/navbar';
import { AppSidebar } from '~/app/layouts/sidebar';
import { RouterView, useRoute, ErrorPage, useError } from '~/infrastructure';
import { useCompare } from '~/domains/compare';
import { useBasketStats } from '~/domains/basket';
import { useCategoriesTree } from '~/domains/category';

const { error } = useError();
const route = useRoute();

const { getCompare } = useCompare();
const { getBasketStats } = useBasketStats();
const { fetchCategoriesTree } = useCategoriesTree();

getCompare();
getBasketStats();
fetchCategoriesTree();
</script>
