<template>
  <UiDrawer
    :is-open="isOpen"
    :width="width"
    color="grey-50"
    class="ui-sidebar__drawer"
    :style="{ top: isScrollingBottom ? '0' : 'var(--header-height)' }"
    style="height: auto !important"
    @update:is-open="$emit('update:isOpen', $event)"
  >
    <template #prepend-content>
      <slot name="prepend-drawer-content" />
    </template>
    <div class="ui-sidebar">
      <UiSidebarHeader>
        <template #logo>
          <slot name="logo" />
        </template>
      </UiSidebarHeader>
      <UiSidebarMenu
        v-model="menuItemsState"
        :items="menu"
        :is-sidebar-open="isOpen"
      />
      <slot name="bottomMenu" />
    </div>
  </UiDrawer>
  <UiBackdrop :visible="backdropVisible" />
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { UiDrawer, UiSidebarMenu, UiBackdrop } from '~/ui';
import UiSidebarHeader from './UiSidebarHeader.vue';
import './UiSidebar.css';
import { useScroll } from '~/infrastructure/composables';
import { type SidebarItem } from '~/app/layouts/sidebar';

defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:isOpen', value: boolean): void;
  (e: 'backdropVisibleChange', data: boolean): void;
}>();

const { isScrollingBottom } = useScroll();

export interface Props {
  menu: SidebarItem[];
  isOpen: boolean;
  width?: string;
}

const menuItemsState = ref<string[]>([]);

const backdropVisible = computed(() => Boolean(menuItemsState.value.length));

watch(backdropVisible, (newBackdropVisibleState) => {
  emit('backdropVisibleChange', newBackdropVisibleState);
});
</script>

<style scoped>
.ui-sidebar {
  background-color: rgb(var(--v-theme-grey-50));
  padding: var(--spacing-6) 0;
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-6);
  max-width: 500px;
  transition: padding-top var(--layout-transition-duration)
    var(--layout-transition-timing);
}
</style>
