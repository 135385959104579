import { defineStore } from 'pinia';
import { ref, readonly, computed } from 'vue';
import { PATH_DICTIONARY } from './constants.d';
import useUserStore from '~/domains/user/user.store';

const useAuthStore = defineStore('auth', () => {
  const userStore = useUserStore();
  const redirectPathAfterLogin = ref(PATH_DICTIONARY.home);

  const isAuth = computed(() => Boolean(userStore.user));

  const setRedirectAfterLogin = (redirectPath = PATH_DICTIONARY.home): void => {
    redirectPathAfterLogin.value = redirectPath;
  };

  return {
    setRedirectAfterLogin,
    redirectPathAfterLogin: readonly(redirectPathAfterLogin),
    isAuth,
  };
});

export default useAuthStore;
