import authRepository from './auth.repository';
import {
  type LogoutStatus,
  type LoginFormData,
  type Status,
  type PingStatus,
  type ForgotPasswordFormData,
  type ForgotPasswordStatus,
  type ResetPasswordForm,
  type ResetPasswordStatus,
  type Link,
} from './types.d';
import userRepository from '~/domains/user/user.repository';
import useUserStore from '~/domains/user/user.store';
import { useCompareStore } from '~/domains/compare';
import { useBasketStatsStore, useBasketStore } from '~/domains/basket';

const authService = {
  setAuthToken(authToken: string): void {
    localStorage.setItem('authToken', authToken);
  },

  getAuthToken(): string | null {
    const token = localStorage.getItem('authToken');

    if (typeof token === 'string') {
      return token;
    }

    return null;
  },

  removeAuthToken(): void {
    localStorage.removeItem('authToken');
  },

  async loginWithToken(token: string): Promise<void> {
    const store = useUserStore();

    this.setAuthToken(token);

    const getUserResponse = await userRepository.getUser();
    const getUserPreferences = await userRepository.getUserPreferences();

    store.setUser(getUserResponse);
    store.setUserPreferences(getUserPreferences);
  },

  async login(loginForm: LoginFormData): Promise<{
    status: Status;
    message?: string | null;
    triesLeft?: string;
  }> {
    try {
      const response = await authRepository.login(loginForm);

      if (
        response.status === 'NEED_TO_CHANGE_PASSWORD' &&
        response.token !== undefined
      ) {
        this.setAuthToken(response.token);
      }

      if (response.status !== 'OK') return response;

      if (response.token === undefined) throw new Error('There is no token');

      await this.loginWithToken(response.token);
      localStorage.removeItem('isNeedToChangePassword');

      return response;
    } catch (error) {
      console.error(error);
      return {
        status: 'INTERNAL_SERVER_ERROR',
      };
    }
  },

  async logout(): Promise<LogoutStatus> {
    const store = useUserStore();
    const { clearCompare } = useCompareStore();
    const { clearBasketStats } = useBasketStatsStore();
    const { clearBasketLines } = useBasketStore();

    try {
      const response = await authRepository.logout();

      store.setUser(null);
      this.removeAuthToken();
      clearCompare();
      clearBasketStats();
      clearBasketLines();

      return response.status;
    } catch (error) {
      return 'INTERNAL_SERVER_ERROR';
    }
  },

  async ping(): Promise<PingStatus> {
    try {
      const response = await authRepository.ping();

      if (response.status !== 'OK') {
        this.removeAuthToken();
      }

      return response.status;
    } catch (error) {
      this.removeAuthToken();

      return 'INTERNAL_SERVER_ERROR';
    }
  },

  async forgotPassword(
    forgotForm: ForgotPasswordFormData,
  ): Promise<ForgotPasswordStatus> {
    try {
      const formData = new FormData();

      formData.append('username', forgotForm.login);
      formData.append('email', forgotForm.email);

      const response = await authRepository.forgotPassword(formData);

      return response.status;
    } catch (error) {
      return 'INTERNAL_SERVER_ERROR';
    }
  },

  async resetPassword(
    resetForm: ResetPasswordForm,
    token: string,
  ): Promise<ResetPasswordStatus> {
    try {
      const formData = new FormData();

      formData.append('username', resetForm.login);
      resetForm.email !== undefined &&
        formData.append('email', resetForm.email);
      formData.append('password', resetForm.password);
      formData.append('token', token);

      const response = await authRepository.resetPassword(formData);

      return response.status;
    } catch (error) {
      return 'INTERNAL_SERVER_ERROR';
    }
  },

  async getBecomeACustomerLink(): Promise<Link | undefined> {
    try {
      return await authRepository.getBecomeACustomerLink();
    } catch (error) {
      console.error(error);
    }
  },
};

export default authService;
