<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 3.25C7.1675 3.25 3.25 7.1675 3.25 12C3.25 16.8325 7.1675 20.75 12 20.75C16.8325 20.75 20.75 16.8325 20.75 12C20.75 7.1675 16.8325 3.25 12 3.25ZM16.317 8.808C16.3751 8.86604 16.4211 8.93495 16.4525 9.01079C16.4839 9.08662 16.5001 9.16791 16.5001 9.25C16.5001 9.33209 16.4839 9.41338 16.4525 9.48921C16.4211 9.56505 16.3751 9.63396 16.317 9.692L10.817 15.192C10.759 15.2501 10.6901 15.2961 10.6142 15.3275C10.5384 15.3589 10.4571 15.3751 10.375 15.3751C10.2929 15.3751 10.2116 15.3589 10.1358 15.3275C10.0599 15.2961 9.99104 15.2501 9.933 15.192L7.683 12.942C7.56577 12.8248 7.49992 12.6658 7.49992 12.5C7.49992 12.3342 7.56577 12.1752 7.683 12.058C7.80023 11.9408 7.95922 11.8749 8.125 11.8749C8.29078 11.8749 8.44977 11.9408 8.567 12.058L10.375 13.866L15.433 8.808C15.491 8.74995 15.5599 8.70389 15.6358 8.67247C15.7116 8.64106 15.7929 8.62488 15.875 8.62488C15.9571 8.62488 16.0384 8.64106 16.1142 8.67247C16.1901 8.70389 16.259 8.74995 16.317 8.808Z"
      fill="currentColor"
    />
  </svg>
</template>
