<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2.7 3.77778C2.7 3.18254 3.18254 2.7 3.77778 2.7H7.33333C7.92857 2.7 8.41111 3.18254 8.41111 3.77778V7.33333C8.41111 7.92857 7.92857 8.41111 7.33333 8.41111H3.77778C3.18254 8.41111 2.7 7.92857 2.7 7.33333V3.77778ZM11.5889 3.77778C11.5889 3.18254 12.0714 2.7 12.6667 2.7H16.2222C16.8175 2.7 17.3 3.18254 17.3 3.77778V7.33333C17.3 7.92857 16.8175 8.41111 16.2222 8.41111H12.6667C12.0714 8.41111 11.5889 7.92857 11.5889 7.33333V3.77778ZM2.7 12.6667C2.7 12.0714 3.18254 11.5889 3.77778 11.5889H7.33333C7.92857 11.5889 8.41111 12.0714 8.41111 12.6667V16.2222C8.41111 16.8175 7.92857 17.3 7.33333 17.3H3.77778C3.18254 17.3 2.7 16.8175 2.7 16.2222V12.6667ZM11.5889 12.6667C11.5889 12.0714 12.0714 11.5889 12.6667 11.5889H16.2222C16.8175 11.5889 17.3 12.0714 17.3 12.6667V16.2222C17.3 16.8175 16.8175 17.3 16.2222 17.3H12.6667C12.0714 17.3 11.5889 16.8175 11.5889 16.2222V12.6667Z"
      stroke="currentColor"
      stroke-width="1.4"
    />
  </svg>
</template>
