<template>
  <div
    v-if="isDevMode"
    style="
      display: block;
      position: fixed;
      bottom: 0;
      left: 16px;
      z-index: 9999;
    "
  >
    <UiMenu :offset="8">
      <template #activator="{ props }">
        <UiButton
          color="primary"
          icon="mdi-hammer-wrench"
          class="mb-4"
          v-bind="props"
          :rounded="'xxl'"
        ></UiButton>
      </template>
      <UiList :density="'compact'">
        <UiListItem v-if="currentBranchName">
          Branch: {{ currentBranchName }}
        </UiListItem>
        <UiListItem>
          Mock mode:
          <UiChip
            :size="'x-small'"
            :bg-color="isMockMode ? 'green' : 'red'"
            text-color="white"
          >
            {{ isMockMode ? 'ON' : 'OFF' }}
          </UiChip>
        </UiListItem>
      </UiList>
    </UiMenu>
  </div>
</template>

<script setup lang="ts">
import { UiMenu, UiList, UiListItem, UiChip, UiButton } from '~/ui';

const isDevMode = import.meta.env.DEV;
const isMockMode = import.meta.env.VITE_MODE === 'mock';
const currentBranchName = import.meta.env.VITE_CURRENT_BRANCH_NAME;
</script>
