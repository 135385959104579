<template>
  <UiButtonLabeled
    label="components.productsDataTable.paginator.comparePanel.goToCompare.text"
    :variant="settings.variant"
    rounded="xl"
    :to="settings.link"
  >
    <template #prepend>
      <UiBadge
        :offset-y="-5"
        :content="compareCount"
        :color="compareCount ? 'error' : 'transparent'"
        :style="{
          marginRight: compareCount ? '14px' : '0',
        }"
        animate
      >
        <UiIcon :icon="settings.icon" />
      </UiBadge>
    </template>
  </UiButtonLabeled>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { UiButtonLabeled, UiIcon, UiBadge } from '~/ui';
import { useCompareStore } from '~/domains/compare';
import { useUserStore } from '~/domains/user';
import { type Variant } from '~/ui/UiButton/UiButton.vue';

const compare = useCompareStore();
const userStore = useUserStore();
const compareCount = computed(() => {
  let compareCountVal = '';
  // Если данных от запроса /compare нет то вставляем данные от запроса /user/info
  if (compare.count === undefined || compare.count === '') {
    if (userStore.user?.compareOverview.productsCount !== undefined)
      compareCountVal =
        userStore.user?.compareOverview.productsCount !== 0
          ? userStore.user?.compareOverview.productsCount.toString()
          : '';
  }
  // Иначе вставляем данные от запроса /compare
  else compareCountVal = compare.count !== '0' ? compare.count : '';

  return Number(compareCountVal) < 1000 ? compareCountVal : '999+';
});

interface Settings {
  id: number;
  text?: string;
  icon: string;
  variant: Variant;
  link: string;
}

const settings: Settings = {
  id: 3,
  icon: '$compare',
  variant: 'text',
  link: '/compare',
};
</script>
