import { type ChangeUserInfoRequest } from '../types.d';
import { type UserChangeUserInfo } from '~/domains/user';

export default function userChangeInfoSerializer(
  data: UserChangeUserInfo,
): ChangeUserInfoRequest {
  return {
    last_name: data.lastName ?? '',
    first_name: data.firstName ?? '',
    middle_name: data.secondName ?? '',
    email: data.email ?? '',
    language: data.language,
    phone: data.phone ?? '',
    phone2: data.phone2 ?? '',
    mobile: data.mobile ?? '',
    fax: data.fax ?? '',
    title: data.title ?? '',
    sex: data.gender === 'Male' ? 'M' : 'F',
    default_company_id: data.defaultCompany,
  };
}
