<template>
  <UiButtonLabeled
    label="page.basket.title"
    variant="outlined"
    rounded="xl"
    to="/basket"
    :text="amountText"
  >
    <template #prepend>
      <div :style="{ width: badgeWidth ? `${+badgeWidth + 20}px` : 'auto' }">
        <UiBadge
          ref="uiBadge"
          :offset-y="-5"
          :content="basketCount"
          :color="basketCount ? 'error' : 'transparent'"
          :style="{
            marginRight: basketCount ? '14px' : '0',
          }"
          animate
        >
          <UiIcon icon="$cart" size="auto" />
        </UiBadge>
      </div>
    </template>
  </UiButtonLabeled>
</template>

<script setup lang="ts">
import { UiButtonLabeled, UiIcon, UiBadge } from '~/ui';
import { computed, type ComputedRef, ref, watchEffect, type Ref } from 'vue';
import { useBasketStatsStore } from '~/domains/basket';
import { storeToRefs } from 'pinia';
import { useElementSize } from '@vueuse/core';

const uiBadge = ref();
const { basketStats, getQuantity } = storeToRefs(useBasketStatsStore());
const currency = computed(() => basketStats.value?.currency ?? '');
const amountText: ComputedRef<string> = computed(() =>
  basketStats.value?.amount !== undefined
    ? `${basketStats.value?.amount.toString()} ${currency.value}`
    : '',
);
let badgeWidth: Ref<any> | null = null;

const basketCount = computed(() =>
  getQuantity.value !== '0' ? getQuantity.value : '',
);

watchEffect(() => {
  if (uiBadge?.value?.$el?.querySelector('.v-badge__badge') !== undefined) {
    const elBadge: HTMLElement =
      uiBadge.value.$el.querySelector('.v-badge__badge');
    const { width } = useElementSize(elBadge);
    badgeWidth = width;
  }
});
</script>
