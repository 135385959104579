<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon / arrow">
      <path
        id="Vector"
        d="M4.0625 10.9375L9.6875 16.5625M9.6875 16.5625L15.3125 10.9375M9.6875 16.5625V3.4375"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
