<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="reload reserve">
      <path
        id="Vector"
        d="M14.258 13.5L16.5657 12.875M16.5657 12.875L17.1426 15.375M16.5657 12.875C16.0532 14.4483 15.1222 15.822 13.8902 16.823C12.6582 17.824 11.1802 18.4075 9.64258 18.5C8.22117 18.5003 6.83414 18.0266 5.66987 17.1432C4.5056 16.2599 3.62053 15.0097 3.13489 13.5625M5.02719 8.5L2.7195 9.125M2.7195 9.125L2.14258 6.625M2.7195 9.125C3.68873 6.25 6.66566 3.5 9.64258 3.5C11.0709 3.50435 12.463 3.9872 13.6281 4.88235C14.7932 5.7775 15.6742 7.04113 16.1503 8.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
