<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.5 1.5C9.52219 1.5 7.58879 2.08649 5.9443 3.1853C4.29981 4.28412 3.01809 5.8459 2.26121 7.67316C1.50433 9.50043 1.3063 11.5111 1.69215 13.4509C2.078 15.3907 3.03041 17.1725 4.42894 18.5711C5.82746 19.9696 7.60929 20.922 9.5491 21.3078C11.4889 21.6937 13.4996 21.4957 15.3268 20.7388C17.1541 19.9819 18.7159 18.7002 19.8147 17.0557C20.9135 15.4112 21.5 13.4778 21.5 11.5C21.5 8.84783 20.4464 6.30429 18.5711 4.42893C16.6957 2.55357 14.1522 1.5 11.5 1.5ZM11.5 20.0714C9.80473 20.0714 8.14754 19.5687 6.73797 18.6269C5.32841 17.685 4.22979 16.3464 3.58104 14.7801C2.93229 13.2139 2.76254 11.4905 3.09327 9.8278C3.424 8.1651 4.24035 6.63782 5.43909 5.43908C6.63782 4.24035 8.16511 3.424 9.8278 3.09327C11.4905 2.76254 13.2139 2.93228 14.7801 3.58103C16.3464 4.22978 17.685 5.3284 18.6269 6.73797C19.5687 8.14753 20.0714 9.80473 20.0714 11.5C20.0714 13.7733 19.1684 15.9535 17.5609 17.5609C15.9535 19.1684 13.7733 20.0714 11.5 20.0714Z"
      fill="currentColor"
    />
    <path
      d="M10.9857 5.89258H12.319V13.0102H10.9857V5.89258ZM11.6523 14.9514C11.4546 14.9514 11.2612 15.0083 11.0968 15.115C10.9323 15.2216 10.8042 15.3732 10.7285 15.5506C10.6528 15.7279 10.633 15.9231 10.6716 16.1113C10.7101 16.2996 10.8054 16.4726 10.9452 16.6083C11.0851 16.744 11.2633 16.8365 11.4573 16.8739C11.6512 16.9114 11.8523 16.8922 12.035 16.8187C12.2178 16.7452 12.3739 16.6208 12.4838 16.4612C12.5937 16.3016 12.6523 16.114 12.6523 15.922C12.6523 15.6646 12.547 15.4177 12.3595 15.2357C12.1719 15.0537 11.9176 14.9514 11.6523 14.9514Z"
      fill="currentColor"
    />
  </svg>
</template>
