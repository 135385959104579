<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M7.03145 3.59375H6.8752C6.96114 3.59375 7.03145 3.52344 7.03145 3.4375V3.59375H12.9689V3.4375C12.9689 3.52344 13.0393 3.59375 13.1252 3.59375H12.9689V5H14.3752V3.4375C14.3752 2.74805 13.8147 2.1875 13.1252 2.1875H6.8752C6.18575 2.1875 5.6252 2.74805 5.6252 3.4375V5H7.03145V3.59375ZM16.8752 5H3.1252C2.7795 5 2.5002 5.2793 2.5002 5.625V6.25C2.5002 6.33594 2.57051 6.40625 2.65645 6.40625H3.83614L4.31856 16.6211C4.34981 17.2871 4.90059 17.8125 5.5666 17.8125H14.4338C15.1018 17.8125 15.6506 17.2891 15.6818 16.6211L16.1643 6.40625H17.3439C17.4299 6.40625 17.5002 6.33594 17.5002 6.25V5.625C17.5002 5.2793 17.2209 5 16.8752 5ZM14.2834 16.4062H5.717L5.24434 6.40625H14.7561L14.2834 16.4062Z"
      fill="currentColor"
    />
  </svg>
</template>
