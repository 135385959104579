<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M8 7.99997H3.25V3.25122H8V7.99997ZM16.7487 7.99997H12V3.25122H16.7487V7.99997ZM8 16.75H3.25V12H8V16.75ZM16.7487 16.75H12V12H16.7487V16.75Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
