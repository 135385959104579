<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.6501 10C4.6501 7.04528 7.04537 4.65001 10.0001 4.65001C12.9548 4.65001 15.3501 7.04528 15.3501 10C15.3501 12.9547 12.9548 15.35 10.0001 15.35C7.04537 15.35 4.6501 12.9547 4.6501 10ZM10.0001 3.35001C6.3274 3.35001 3.3501 6.32731 3.3501 10C3.3501 13.6727 6.3274 16.65 10.0001 16.65C11.6024 16.65 13.0723 16.0833 14.2204 15.1395L19.0404 19.9595C19.2942 20.2134 19.7058 20.2134 19.9596 19.9595C20.2135 19.7057 20.2135 19.2941 19.9596 19.0403L15.1396 14.2203C16.0834 13.0722 16.6501 11.6023 16.6501 10C16.6501 6.32731 13.6728 3.35001 10.0001 3.35001Z"
      fill="currentColor"
    />
  </svg>
</template>
