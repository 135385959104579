<template>
  <UiForm @submit="handleSubmit">
    <UiInput
      ref="input"
      :model-value="modelValue"
      :single-line="true"
      :hide-details="true"
      :label="label"
      class="ui-search"
      :density="density"
      :disabled="disabled"
      @update:model-value="handleInput"
    >
      <template v-if="icon" #prepend-inner>
        <UiIcon icon="$search" />
      </template>
      <template v-if="button" #append-inner>
        <UiButton
          color="primary"
          variant="flat"
          :height="36"
          icon="mdi-magnify"
          min-width="60"
          @click="handleSubmit"
        />
      </template>
    </UiInput>
  </UiForm>
</template>

<script setup lang="ts">
import { UiButton } from '../UiButton';
import { UiInput } from '../UiInput';
import { UiIcon } from '../UiIcon';
import { type Density } from '../UiInput/UiInput.vue';
import { UiForm } from '../UiForm';
import { ref } from 'vue';

interface Props {
  modelValue: string;
  button?: boolean;
  icon?: boolean;
  density?: Density;
  disabled?: boolean;
  label: string;
  emptySubmit?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  density: 'default',
  disabled: false,
  emptySubmit: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'submit'): void;
}>();

const input = ref();

const handleInput = (value: string): void => {
  const trimedValue = value.trim();
  emit('update:modelValue', trimedValue);
};

const handleSubmit = (): void => {
  input.value.blur();
  if (!props.emptySubmit) {
    if (props.modelValue.length === 0) return;
  }
  emit('submit');
};
</script>

<style scoped>
:deep(.v-field--appended) {
  padding-inline-end: 4px;
}
</style>
