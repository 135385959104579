<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon / close">
      <path
        id="Vector"
        d="M11.6434 9.99901L16.6552 4.99813C16.8747 4.77862 16.998 4.48091 16.998 4.17048C16.998 3.86005 16.8747 3.56233 16.6552 3.34282C16.4358 3.12332 16.1381 3 15.8277 3C15.5173 3 15.2196 3.12332 15.0002 3.34282L10 8.35536L4.99983 3.34282C4.78036 3.12332 4.48268 3 4.1723 3C3.86191 3 3.56424 3.12332 3.34476 3.34282C3.12529 3.56233 3.00199 3.86005 3.00199 4.17048C3.00199 4.48091 3.12529 4.77862 3.34476 4.99813L8.35659 9.99901L3.34476 14.9999C3.23552 15.1083 3.14881 15.2372 3.08964 15.3792C3.03047 15.5213 3 15.6736 3 15.8275C3 15.9814 3.03047 16.1338 3.08964 16.2758C3.14881 16.4179 3.23552 16.5468 3.34476 16.6552C3.45312 16.7644 3.58203 16.8512 3.72406 16.9103C3.86609 16.9695 4.01843 17 4.1723 17C4.32616 17 4.47851 16.9695 4.62054 16.9103C4.76257 16.8512 4.89148 16.7644 4.99983 16.6552L10 11.6427L15.0002 16.6552C15.1085 16.7644 15.2374 16.8512 15.3795 16.9103C15.5215 16.9695 15.6738 17 15.8277 17C15.9816 17 16.1339 16.9695 16.2759 16.9103C16.418 16.8512 16.5469 16.7644 16.6552 16.6552C16.7645 16.5468 16.8512 16.4179 16.9104 16.2758C16.9695 16.1338 17 15.9814 17 15.8275C17 15.6736 16.9695 15.5213 16.9104 15.3792C16.8512 15.2372 16.7645 15.1083 16.6552 14.9999L11.6434 9.99901Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
