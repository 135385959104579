<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon">
      <path
        id="Vector"
        d="M6.44444 2.75H17.5556C19.5959 2.75 21.25 4.40406 21.25 6.44444V17.5556C21.25 19.5959 19.5959 21.25 17.5556 21.25H6.44444C4.40406 21.25 2.75 19.5959 2.75 17.5556V6.44444C2.75 4.40406 4.40406 2.75 6.44444 2.75Z"
        stroke="#58627C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
