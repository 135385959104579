import { createRouter, createWebHistory } from 'vue-router/auto';
import { setupLayouts } from 'virtual:generated-layouts';
import { authGuard, loginByTokenGuard } from './guards';

const router = createRouter({
  history: createWebHistory(),
  extendRoutes: (_routes) => {
    const routes = [
      ..._routes,
      {
        name: 'product',
        path: '/product/:id(.*)',
        component: async () =>
          await import('~/app/pages/product/[id].vue').then(
            (r) => r.default ?? r,
          ),
      },
    ];
    return setupLayouts(routes);
  },
});

router.beforeEach((to, from) => {
  const isFromQueryEnableTranslateOff = from.query['translate-off'] === 'true';
  const isToQueryNotConsistTranslateOff =
    to.query['translate-off'] === undefined;

  if (isFromQueryEnableTranslateOff && isToQueryNotConsistTranslateOff) {
    to.query['translate-off'] = 'true';

    return { ...to };
  } else {
    return true;
  }
});

router.beforeEach(loginByTokenGuard);
router.beforeResolve(authGuard);

router.beforeEach((to) => {
  if (to.path !== '/create-password' && to.path !== '/reset-password') return;
  if (to.query.token !== undefined) return;
  return '/login';
});

router.onError((_, to) => {
  window.location.href = to.fullPath;
});

export default router;
