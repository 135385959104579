<template>
  <UiAppBar
    color="blue-dark-800"
    :height="40"
    :style="{
      transform: `translateY(${
        isScrollingBottom ? 'calc(var(--header-height) * -1)' : '0'
      })`,
    }"
  >
    <UiContainer class="ui-header__container">
      <slot />
    </UiContainer>
  </UiAppBar>
</template>

<script setup lang="ts">
import { UiContainer, UiAppBar } from '~/ui';
import { useScroll } from '~/infrastructure/composables';

const { isScrollingBottom } = useScroll();
</script>

<style scoped>
.ui-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2100;
  transition: transform var(--layout-transition-duration)
    var(--layout-transition-timing);
  height: var(--header-height);
}

.ui-header__sheet,
.ui-header__container {
  height: 100%;
}

.ui-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
