<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon / info">
      <g id="Vector">
        <path
          d="M7.33398 5.3335C7.33398 5.70169 7.63246 6.00016 8.00065 6.00016C8.36884 6.00016 8.66732 5.70169 8.66732 5.3335C8.66732 4.96531 8.36884 4.66683 8.00065 4.66683C7.63246 4.66683 7.33398 4.96531 7.33398 5.3335ZM8.00065 13.3335C5.06065 13.3335 2.66732 10.9402 2.66732 8.00016C2.66732 5.06016 5.06065 2.66683 8.00065 2.66683C10.9407 2.66683 13.334 5.06016 13.334 8.00016C13.334 10.9402 10.9407 13.3335 8.00065 13.3335ZM8.00065 1.3335C7.12517 1.3335 6.25827 1.50593 5.44943 1.84097C4.64059 2.176 3.90566 2.66706 3.28661 3.28612C2.03636 4.53636 1.33398 6.23205 1.33398 8.00016C1.33398 9.76827 2.03636 11.464 3.28661 12.7142C3.90566 13.3333 4.64059 13.8243 5.44943 14.1594C6.25827 14.4944 7.12517 14.6668 8.00065 14.6668C9.76876 14.6668 11.4645 13.9645 12.7147 12.7142C13.9649 11.464 14.6673 9.76827 14.6673 8.00016C14.6673 7.12468 14.4949 6.25778 14.1598 5.44894C13.8248 4.6401 13.3338 3.90517 12.7147 3.28612C12.0956 2.66706 11.3607 2.176 10.5519 1.84097C9.74304 1.50593 8.87613 1.3335 8.00065 1.3335ZM7.33398 10.6668C7.33398 11.035 7.63246 11.3335 8.00065 11.3335C8.36884 11.3335 8.66732 11.035 8.66732 10.6668V8.00016C8.66732 7.63197 8.36884 7.3335 8.00065 7.3335C7.63246 7.3335 7.33398 7.63197 7.33398 8.00016V10.6668Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
