<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ci:filter">
      <path
        id="Vector"
        d="M16.6673 4.66683C16.6673 4.20016 16.6673 3.96683 16.5757 3.7885C16.4961 3.63176 16.3689 3.50427 16.2123 3.42433C16.034 3.3335 15.8007 3.3335 15.334 3.3335H4.66732C4.20065 3.3335 3.96732 3.3335 3.78898 3.42433C3.63219 3.50422 3.50471 3.6317 3.42482 3.7885C3.33398 3.96683 3.33398 4.20016 3.33398 4.66683V5.281C3.33398 5.48516 3.33398 5.58683 3.35732 5.68266C3.37772 5.76798 3.41147 5.84954 3.45732 5.92433C3.50815 6.00766 3.58065 6.08016 3.72398 6.22433L7.94315 10.4427C8.08732 10.5868 8.15982 10.6593 8.21065 10.7427C8.25648 10.8177 8.29065 10.8993 8.31065 10.9843C8.33398 11.0793 8.33398 11.1802 8.33398 11.3793V15.3427C8.33398 16.0568 8.33398 16.4143 8.48398 16.6293C8.54906 16.7223 8.63245 16.801 8.72903 16.8606C8.82562 16.9203 8.93336 16.9595 9.04565 16.976C9.30482 17.0143 9.62482 16.8552 10.2632 16.5352L10.9298 16.2018C11.1982 16.0685 11.3315 16.0018 11.429 15.9018C11.5155 15.8135 11.5812 15.707 11.6215 15.5902C11.6673 15.4585 11.6673 15.3085 11.6673 15.0093V11.386C11.6673 11.1818 11.6673 11.0802 11.6907 10.9843C11.711 10.899 11.7448 10.8174 11.7907 10.7427C11.8407 10.6593 11.9132 10.5877 12.0548 10.446L12.0582 10.4427L16.2773 6.22433C16.4207 6.08016 16.4923 6.00766 16.544 5.92433C16.5899 5.84958 16.6237 5.76801 16.644 5.68266C16.6673 5.5885 16.6673 5.48683 16.6673 5.28766V4.66683Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
