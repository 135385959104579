<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M1.66602 7.38404V14C1.66602 14.7073 1.95867 15.3856 2.47961 15.8857C3.00054 16.3858 3.70708 16.6667 4.44379 16.6667H15.5549C16.2916 16.6667 16.9982 16.3858 17.5191 15.8857C18.04 15.3856 18.3327 14.7073 18.3327 14V6.00004C18.3327 5.2928 18.04 4.61452 17.5191 4.11442C16.9982 3.61433 16.2916 3.33337 15.5549 3.33337H4.44379C3.70708 3.33337 3.00054 3.61433 2.47961 4.11442C1.95867 4.61452 1.66602 5.2928 1.66602 6.00004V7.38404ZM4.44379 4.66671H15.5549C15.9233 4.66671 16.2765 4.80718 16.537 5.05723C16.7975 5.30728 16.9438 5.64642 16.9438 6.00004V6.98671L9.99935 10.576L3.0549 6.98671V6.00004C3.0549 5.64642 3.20123 5.30728 3.4617 5.05723C3.72217 4.80718 4.07544 4.66671 4.44379 4.66671ZM3.0549 8.50137L9.67018 11.92C9.77135 11.9723 9.88445 11.9997 9.99935 11.9997C10.1142 11.9997 10.2273 11.9723 10.3285 11.92L16.9438 8.50137V14C16.9438 14.3537 16.7975 14.6928 16.537 14.9428C16.2765 15.1929 15.9233 15.3334 15.5549 15.3334H4.44379C4.07544 15.3334 3.72217 15.1929 3.4617 14.9428C3.20123 14.6928 3.0549 14.3537 3.0549 14V8.50137Z"
      fill="currentColor"
    />
  </svg>
</template>
