<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1457 13.8333H3.85232C3.62113 13.8239 3.39407 13.769 3.18412 13.6717C2.97418 13.5744 2.78548 13.4367 2.62882 13.2664C2.47216 13.0961 2.35062 12.8966 2.27114 12.6793C2.19167 12.462 2.15582 12.2312 2.16565 12V10C2.16565 9.86739 2.21833 9.74022 2.3121 9.64645C2.40587 9.55268 2.53304 9.5 2.66565 9.5C2.79826 9.5 2.92544 9.55268 3.01921 9.64645C3.11297 9.74022 3.16565 9.86739 3.16565 10V12C3.14844 12.198 3.20808 12.3949 3.33221 12.5501C3.45633 12.7052 3.63542 12.8067 3.83232 12.8333H12.1457C12.3426 12.8067 12.5216 12.7052 12.6458 12.5501C12.7699 12.3949 12.8295 12.198 12.8123 12V10C12.8123 9.86739 12.865 9.74022 12.9588 9.64645C13.0525 9.55268 13.1797 9.5 13.3123 9.5C13.4449 9.5 13.5721 9.55268 13.6659 9.64645C13.7596 9.74022 13.8123 9.86739 13.8123 10V12C13.8323 12.4636 13.6684 12.9163 13.3562 13.2596C13.0441 13.603 12.609 13.8092 12.1457 13.8333Z"
      fill="currentColor"
    />
    <path
      d="M8.00185 10.4999C7.93616 10.5002 7.87106 10.4874 7.81039 10.4622C7.74971 10.437 7.69468 10.4 7.64852 10.3532L4.98185 7.68656C4.89353 7.59178 4.84545 7.46642 4.84773 7.33688C4.85002 7.20735 4.90249 7.08376 4.9941 6.99215C5.08571 6.90054 5.2093 6.84807 5.33884 6.84578C5.46837 6.8435 5.59373 6.89158 5.68852 6.9799L8.00185 9.29323L10.3152 6.9799C10.41 6.89158 10.5353 6.8435 10.6649 6.84578C10.7944 6.84807 10.918 6.90054 11.0096 6.99215C11.1012 7.08376 11.1537 7.20735 11.156 7.33688C11.1583 7.46642 11.1102 7.59178 11.0219 7.68656L8.35518 10.3532C8.30902 10.4 8.25399 10.437 8.19331 10.4622C8.13264 10.4874 8.06754 10.5002 8.00185 10.4999Z"
      fill="currentColor"
    />
    <path
      d="M8 10.5001C7.86793 10.4984 7.74175 10.4451 7.64836 10.3517C7.55496 10.2583 7.50173 10.1322 7.5 10.0001V2.66675C7.5 2.53414 7.55268 2.40696 7.64645 2.31319C7.74022 2.21943 7.86739 2.16675 8 2.16675C8.13261 2.16675 8.25978 2.21943 8.35355 2.31319C8.44732 2.40696 8.5 2.53414 8.5 2.66675V10.0001C8.49827 10.1322 8.44504 10.2583 8.35164 10.3517C8.25825 10.4451 8.13207 10.4984 8 10.5001Z"
      fill="currentColor"
    />
  </svg>
</template>
