<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9 12.5C9 13.88 11.239 15 14 15C16.761 15 19 13.88 19 12.5M1 7.5C1 8.88 3.239 10 6 10C7.126 10 8.165 9.814 9 9.5M1 11C1 12.38 3.239 13.5 6 13.5C7.126 13.5 8.164 13.314 9 13M14 11C11.239 11 9 9.88 9 8.5C9 7.12 11.239 6 14 6C16.761 6 19 7.12 19 8.5C19 9.88 16.761 11 14 11Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 3.5V14.5C1 15.88 3.239 17 6 17C7.126 17 8.164 16.814 9 16.5M9 16.5V8.5M9 16.5C9 17.88 11.239 19 14 19C16.761 19 19 17.88 19 16.5V8.5M11 6.5V3.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 6C3.239 6 1 4.88 1 3.5C1 2.12 3.239 1 6 1C8.761 1 11 2.12 11 3.5C11 4.88 8.761 6 6 6Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
