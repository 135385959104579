<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
  >
    <path
      d="M1.30078 5.05L5.79367 9.54289C6.1842 9.93342 6.81736 9.93342 7.20789 9.54289L11.7008 5.05"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
