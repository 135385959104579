import { type SocialsRepository } from '../ports';
import { type Social } from '../entities';

type GetSocialsUseCase = (dependencies: {
  socialsRepository: SocialsRepository;
}) => Promise<Social[] | undefined>;

const getSocialsUseCase: GetSocialsUseCase = async ({ socialsRepository }) => {
  return await socialsRepository.getSocials();
};

export default getSocialsUseCase;
