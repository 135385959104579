import { authService } from '~/domains/auth';

export default async (to: {
  path: string;
  query: {
    token: string;
    redirect?: string;
  };
}): Promise<string | undefined> => {
  if (to.path === '/login-by-token' && to.query.token !== undefined) {
    const token = to.query.token;
    try {
      await authService.loginWithToken(token);
      return to.query.redirect ?? '/';
    } catch (error) {
      console.error(error);
      return '/login';
    }
  }
};
