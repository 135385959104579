<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 21361">
      <path
        id="Vector 25"
        d="M10.4 1.6001L4.37062 7.62948C4.16593 7.83417 4.16593 8.16603 4.37062 8.37071L10.4 14.4001"
        stroke="currentColor"
        stroke-width="1.6"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>
