import SocialsJSON from '../data/socials.json';
import { type SocialsRepository } from '../../ports';

const socialsLocalRepository: SocialsRepository = {
  async getSocials() {
    return SocialsJSON;
  },
};

export default socialsLocalRepository;
