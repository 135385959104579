<template>
  <UiDialog
    :model-value="modelValue"
    title="components.choseYourSupplierDialog.title"
    width="472"
    :loading="loading"
    scrollable
    @update:model-value="handleModalValueUpdate"
  >
    <UiSearch
      :model-value="searchingValue"
      :disabled="loading"
      :label="t('common.buttons.Search.text')"
      icon
      @update:model-value="onSearchChange"
    />
    <UiList :disabled="loading">
      <UiListItem
        v-for="supplier in searchItemsByValue"
        :key="supplier.id"
        :value="supplier.id"
        @click="handleClick(supplier.id)"
      >
        <UiListItemTitle>
          {{ supplier.name }}
        </UiListItemTitle>

        <template v-if="isActive(supplier.id)" #append>
          <UiIcon icon="$check" color="primary" />
        </template>
      </UiListItem>
    </UiList>
  </UiDialog>
</template>

<script setup lang="ts">
import { computed, type Ref, ref } from 'vue';
import {
  UiDialog,
  UiList,
  UiListItem,
  UiListItemTitle,
  UiSearch,
  UiIcon,
} from '~/ui';
import { useI18n } from '~/infrastructure';
import useUserStore from '~/domains/user/user.store';
import {
  type UserSelected,
  useChangeUserSettingsAdapter,
} from '~/domains/user';
import useUserSearch from '~/domains/user/composables/useUserSearch';

export interface Props {
  modelValue: boolean;
}
defineProps<Props>();

const emit = defineEmits<(e: 'update:modelValue', value: boolean) => void>();

const { t } = useI18n();
const { user } = useUserStore();
const suppliers = computed(() => user?.suppliers);
const { searchingValue, searchItemsByValue, onSearchChange } =
  useUserSearch(suppliers);

const isActive = (id: string): boolean => {
  return user?.selectedSupplier?.id === id;
};

const loading: Ref<boolean> = ref(false);
const { changeUserSettings } = useChangeUserSettingsAdapter();

const handleClick = async (id: string): Promise<UserSelected> => {
  try {
    loading.value = true;

    await changeUserSettings({
      supplierId: id,
    });

    loading.value = false;
    searchingValue.value = '';
  } catch (err) {
    console.log(err);
    loading.value = false;
  }
};

const handleModalValueUpdate = (value: boolean): void => {
  emit('update:modelValue', value);
  searchingValue.value = '';
};
</script>
