<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.72851 15.2463L4.95765 17.7375C4.95651 17.7323 4.95585 17.7241 4.95797 17.7119L4.95803 17.7116L5.86584 12.4562L5.91835 12.1522L5.69667 11.9377L1.8438 8.20931C1.84379 8.2093 1.84377 8.20928 1.84376 8.20927C1.8401 8.20571 1.83845 8.20317 1.83758 8.2016C1.8366 8.19984 1.83553 8.19737 1.83468 8.19372C1.83277 8.1855 1.83252 8.17293 1.83645 8.15951C1.83783 8.15481 1.83937 8.15117 1.84073 8.14849L7.18779 7.37664L7.49068 7.33292L7.62685 7.05885L10.0001 2.28233L12.3734 7.05885L12.5096 7.33292L12.8125 7.37664L18.1595 8.14848C18.1608 8.1511 18.1623 8.15463 18.1636 8.15918C18.1675 8.17245 18.1672 8.18485 18.1653 8.19297C18.1645 8.19656 18.1634 8.19902 18.1624 8.2008C18.1615 8.2024 18.1598 8.20502 18.156 8.20869L18.1557 8.20898L14.3036 11.9377L14.082 12.1523L14.1345 12.4562L15.0423 17.7116L15.0423 17.7119C15.0445 17.7246 15.0437 17.7328 15.0425 17.738L10.2684 15.2463L9.99845 15.1054L9.72851 15.2463Z"
      stroke="currentColor"
      stroke-width="1.16667"
    />
  </svg>
</template>
