<template>
  <Vue3Snackbar
    bottom
    right
    :duration="4000"
    info="#5697da"
    error="#DC4E47"
    warning="#FE7028"
  >
    <template #message-icon="{ message }">
      <UiIcon v-if="message.type === 'info'" icon="$success" />
      <UiIcon v-if="message.type === 'error'" icon="$warning" />
      <UiIcon v-if="message.type === 'warning'" icon="$warning" />
    </template>
    <template #message-content="{ message }">
      <p class="text-body-3" v-html="message.text"></p>
    </template>
  </Vue3Snackbar>
</template>

<script setup lang="ts">
import { ref, computed, watchEffect, onMounted } from 'vue';
// @ts-expect-error: vue3-snackbar do not have types
import { Vue3Snackbar } from 'vue3-snackbar';
import 'vue3-snackbar/styles';
import UiIcon from '../UiIcon/UiIcon.vue';
import { useScroll } from '~/infrastructure/composables';

const { isScrollingBottom } = useScroll();

const rootElement = ref();
const top = computed(() => (isScrollingBottom.value ? '54px' : '94px'));

watchEffect(() => {
  rootElement.value?.style.setProperty('--ui-snackbar-top', top.value);
});

onMounted(() => {
  rootElement.value = document.querySelector('#vue3-snackbar--container');
});
</script>

<style>
#vue3-snackbar--container {
  --ui-snackbar-top: 0;
  transition: top var(--layout-transition-duration)
    var(--layout-transition-timing);
}

#vue3-snackbar--container.is-top {
  top: var(--ui-snackbar-top);
}
.vue3-snackbar-message {
  border-radius: 16px;
}
.vue3-snackbar-message-wrapper {
  padding: 8px;
}
.vue3-snackbar-message-icon {
  margin-right: 8px;
}
.vue3-snackbar-message-title {
  font-weight: 400;
  font-size: 14px;
}
.vue3-snackbar-message-content {
  width: min(50vw, 250px);
}
</style>
