<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon / scale">
      <path
        id="Vector"
        d="M4.50002 15.7498H13.5M3.60002 3.1498H9.00002H14.4M3.60002 3.1498H14.4M3.60002 3.1498L6.30002 8.5498C6.30002 9.26589 6.01556 9.95264 5.50921 10.459C5.00286 10.9653 4.31611 11.2498 3.60002 11.2498C2.88394 11.2498 2.19718 10.9653 1.69084 10.459C1.18449 9.95264 0.900024 9.26589 0.900024 8.5498L3.60002 3.1498ZM14.4 3.1498L17.1 8.5498C17.1 9.26589 16.8156 9.95264 16.3092 10.459C15.8029 10.9653 15.1161 11.2498 14.4 11.2498C13.6839 11.2498 12.9972 10.9653 12.4908 10.459C11.9845 9.95264 11.7 9.26589 11.7 8.5498L14.4 3.1498ZM9.00002 1.7998V15.7498"
        stroke="currentColor"
        stroke-width="1.44"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
