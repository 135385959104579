<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M15.1428 16.6666V8.33325H5.14283V16.6666H15.1428ZM15.1428 6.66658C15.5849 6.66658 16.0088 6.84218 16.3213 7.15474C16.6339 7.4673 16.8095 7.89122 16.8095 8.33325V16.6666C16.8095 17.1086 16.6339 17.5325 16.3213 17.8451C16.0088 18.1577 15.5849 18.3333 15.1428 18.3333H5.14283C4.7008 18.3333 4.27688 18.1577 3.96432 17.8451C3.65176 17.5325 3.47617 17.1086 3.47617 16.6666V8.33325C3.47617 7.89122 3.65176 7.4673 3.96432 7.15474C4.27688 6.84218 4.7008 6.66658 5.14283 6.66658H12.6428V4.99992C12.6428 4.33688 12.3794 3.70099 11.9106 3.23215C11.4418 2.76331 10.8059 2.49992 10.1428 2.49992C9.47979 2.49992 8.84391 2.76331 8.37506 3.23215L7.19655 2.05364C7.97796 1.27224 9.03776 0.833252 10.1428 0.833252C10.69 0.833252 11.2318 0.941026 11.7373 1.15042C12.2429 1.35982 12.7022 1.66673 13.0891 2.05364C13.476 2.44055 13.7829 2.89988 13.9923 3.4054C14.2017 3.91093 14.3095 4.45274 14.3095 4.99992V6.66658H15.1428ZM10.1428 14.1666C9.7008 14.1666 9.27688 13.991 8.96432 13.6784C8.65176 13.3659 8.47617 12.9419 8.47617 12.4999C8.47617 12.0579 8.65176 11.634 8.96432 11.3214C9.27688 11.0088 9.7008 10.8333 10.1428 10.8333C10.5849 10.8333 11.0088 11.0088 11.3213 11.3214C11.6339 11.634 11.8095 12.0579 11.8095 12.4999C11.8095 12.9419 11.6339 13.3659 11.3213 13.6784C11.0088 13.991 10.5849 14.1666 10.1428 14.1666Z"
      fill="currentColor"
    />
  </svg>
</template>
