import { type Ref, ref } from 'vue';
import { useGetUserInfoAdapter, type UserInfo } from '~/domains/user';

interface ViewModelReturn {
  loading: Ref<boolean>;
  fetchUserInfo: () => Promise<UserInfo | undefined>;
}

export default function getUserInfoViewModel(): ViewModelReturn {
  const getUserInfo = useGetUserInfoAdapter();
  const loading = ref(false);

  const fetchUserInfo = async (): Promise<UserInfo | undefined> => {
    loading.value = true;
    try {
      return await getUserInfo();
    } finally {
      loading.value = false;
    }
  };

  return {
    fetchUserInfo,
    loading,
  };
}
