<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.308 0C11.754 0 13.744 1.99 13.744 4.436V5.368C13.744 5.782 13.408 6.118 12.994 6.118C12.58 6.118 12.244 5.782 12.244 5.368V4.436C12.244 2.816 10.927 1.5 9.308 1.5H4.433C2.816 1.5 1.5 2.816 1.5 4.436V15.565C1.5 17.184 2.816 18.5 4.433 18.5H9.319C10.931 18.5 12.244 17.188 12.244 15.576V14.633C12.244 14.219 12.58 13.883 12.994 13.883C13.408 13.883 13.744 14.219 13.744 14.633V15.576C13.744 18.016 11.758 20 9.319 20H4.433C1.989 20 0 18.011 0 15.565V4.436C0 1.99 1.989 0 4.433 0H9.308ZM17.3881 6.554L20.3161 9.469C20.3422 9.49492 20.3656 9.52187 20.387 9.55039L20.3161 9.469C20.3515 9.50393 20.383 9.5421 20.4104 9.5828C20.4224 9.60125 20.434 9.62032 20.4448 9.63992C20.4535 9.65523 20.4615 9.67122 20.469 9.68746C20.4753 9.70182 20.4814 9.71618 20.487 9.73077C20.4945 9.74977 20.5011 9.76931 20.5069 9.78911C20.5113 9.8047 20.5153 9.82026 20.5188 9.836C20.5233 9.8551 20.5268 9.87432 20.5295 9.89368C20.5311 9.90627 20.5326 9.91947 20.5338 9.93276C20.5361 9.95557 20.5371 9.97774 20.5371 10L20.532 10.062L20.53 10.1017C20.5297 10.1034 20.5295 10.1051 20.5292 10.1068L20.5371 10C20.5371 10.0555 20.531 10.1105 20.519 10.1639C20.5153 10.1797 20.5113 10.1953 20.5068 10.2107C20.5011 10.2307 20.4945 10.2502 20.4871 10.2695C20.4814 10.2838 20.4753 10.2982 20.4688 10.3123C20.4615 10.3288 20.4535 10.3448 20.4449 10.3605C20.434 10.3797 20.4224 10.3988 20.41 10.4172C20.403 10.4282 20.3953 10.439 20.3873 10.4497C20.3635 10.481 20.3375 10.5104 20.3095 10.5377L17.3881 13.447C17.2421 13.593 17.0501 13.666 16.8591 13.666C16.6671 13.666 16.4741 13.593 16.3281 13.445C16.0361 13.151 16.0371 12.677 16.3301 12.385L17.97 10.75H7.7458C7.3318 10.75 6.9958 10.414 6.9958 10C6.9958 9.586 7.3318 9.25 7.7458 9.25H17.972L16.3301 7.616C16.0371 7.324 16.0351 6.85 16.3281 6.556C16.6201 6.262 17.0941 6.262 17.3881 6.554Z"
      fill="currentColor"
    />
  </svg>
</template>
