export default {
  primary: '#1C4D88',
  'primary-200': '#C4DAF3',
  error: '#FF5656',
  red: '#DC4E47',
  'red-50': '#FD2D40',
  'grey-50': '#F6F7F9',
  'grey-100': '#ECEEF2',
  'grey-400': '#8791A9',
  'grey-600': '#58627C',
  'grey-800': '#3B4151',
  'fours-grey-200': '#BFC7DB',
  black: '#0A0A0A',
  'blue-50': '#F2F7FD',
  'blue-100': 'DCE6FF',
  'blue-200': '#C4DAF3',
  'blue-500': '#5697DA',
  'blue-600': '#0500EA',
  'blue-700': '#1C4D88',
  'blue-900': '#2397FF',
  'blue-dark-800': '#193E6A',
  purple: '#7964C0',
  green: '#87B055',
  done: '#35D487',
  white: '#fff',
  orange: '#FE7028',
  yellow: '#F3D743',
};
